<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <div class="box">
            <div  class="columns ">
               
                <div class="column is-3">
                    <b-field>

                        <b-select  v-model="filtroEtapa" :placeholder="$t('SISTEMA.ETAPA')"  @input="loadAsyncData()" expanded>
                            <option :value="null"></option>
                            <template v-for="(item, index) in this.listaEtapas">
                                <option v-if="filtroEtapa == item.valor" selected v-bind:key="index" :value="item.valor">
                                    {{item.descricao}}
                                </option>
                                <option v-else v-bind:key="index" :value="item.valor">
                                    {{item.descricao}}
                                </option>
                            </template>
                        </b-select>                                 
                    </b-field>
                </div>  
                <div  class="column is-3">
                    <b-field>
                        <b-select  v-model="vendedorFiltro" :placeholder="$t('GUIALIST.VENDEDOR')"  @input="loadAsyncData()" expanded>
                        <option :value="null"></option>
                        <template v-for="(item, index) in this.listaVendedoresUnidade">
                            <option v-if="vendedorFiltro == item.id" selected v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                            <option v-else v-bind:key="index" :value="item.id">
                                {{item.nome}}
                            </option>
                        </template>
                    </b-select>                                 
                    </b-field>
                </div> 

                <div  class="column is-1">
                    
                    <b-button icon-left="file-excel" class="is-info center ml-1"  :title="$t('SISTEMA.EXPORTAR')"
                    @click.prevent.stop="exportarResultados()">{{$t('SISTEMA.EXPORTAR')}}</b-button>                       
                </div>

                
           

            </div>            
        </div>

        <div  v-for="(item, index) in listaAtrasados" v-bind:key="index" class="columns is-multiline cardAtendimento" style="margin-bottom: 5px !important;">
            <div   :class="item.atraso ? 'bg-atraso column is-3' : ' column is-3' ">
                <b-tag  type ="is-light" size="is-medium"> <span class="icon is-small"></span>{{retornaDescricaoEtapa(item.alertaStatus)}} </b-tag>                                                    
            </div> 
            <div :class="item.atraso ? 'bg-atraso column is-4' : ' column is-4' " >
                <small>{{$t('SISTEMA.CLIENTE')}}</small>
                <br />                
                <span>                                  
                    <b>{{ item.clienteCodigoExterno }} - {{ item.clienteNome }}</b>                   
                </span>
            </div>
            <div :class="item.atraso ? 'bg-atraso column is-4' : ' column is-3' ">
                <span>
                    <small>{{$t('ATENDENTE.VENDEDOR')}}</small>
                    <br />
                    <span v-if="item.funcionarioComercialNome"><b>{{ item.funcionarioComercialNome.toUpperCase() }}</b></span>
                    <span v-else><b>a definir</b></span>
                </span>               
            </div>                        
            <div :class="item.atraso ? 'bg-atraso column is-1' : ' column is-2' "> 

                <router-link  :to="{ name: 'guia', params: { id: item.id}}" 
                    class="button is-primary"  size="is-medium" title="Abrir Projeto">
                    <b-icon icon="pencil" size="is-small"></b-icon>
                </router-link>                                                                      
                  
            </div>                                                                  
        </div>        


                                 
    </section>
</template>

<style scoped>
.cardAtendimento {
    background-color: #fff;
    cursor: pointer;
}

.cardAtendimento:hover {
    background-color: hsl(0, 0%, 96%)
}
</style>



<script>
    import { mapState, mapGetters } from 'vuex'  
    import moment from 'moment'      
            
    export default {
        data() {
            return {                                               
                isLoading: false,
                filtroEtapa: null,
                listaEtapas: [
                    { descricao: "Prioridade a Definir" , valor: "prioridadedefinir"},                    
                    { descricao: "Projetos em Espera" , valor: "projetosespera"},
                    { descricao: "Projetos em Andamento" , valor: "projetosandamento"},            
                    { descricao: "Agendar Visita" , valor: "agendavisita"},
                    { descricao: "Em Negociação" , valor: "emnegociacao"}
                    
                ],                 
                listaAtrasados:[],               
                listaVendedoresUnidade:[],                
                listaGerentesComercialUnidade:[],
                vendedorFiltro: null,   
            }
        },
        components: {  
                                  
        },
        computed: {
            ...mapState([
                'unidadeId',
                'config',
                'usuario'                
            ]),
            ...mapGetters([
                'isInRole'
            ]),
        },
        watch: {            
            unidadeId: function(novaUnidade) {                 
                this.loadAsyncData()                                                
            },
        }, 
        methods: {
            retornaDescricaoEtapa(alertaStatus){
                if(alertaStatus==0){
                    return "Prioridade a Definir";
                }else if(alertaStatus==1){
                    return "Projetos em Espera";
                }else if(alertaStatus==2){
                    return "Projetos em Andamento";
                }else if(alertaStatus==3){
                    return "Agendar Visita";
                }else if(alertaStatus==5 || alertaStatus==7){
                    return "Em Negociação";
                }
            },
            carregarVendedoresUnidade(){


                this.$http.get('/api/search/FuncionarioComercial')
                .then(res => res.data)
                .then(data => {
                    if (data != null) {
                        this.listaVendedoresUnidade = data;
                    }
                });
                

            },
            exportarResultados(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
              
                if(this.vendedorFiltro>0)
                    params.push(`comercialId=${this.vendedorFiltro}`);    


                if(this.filtroEtapa)
                    params.push(`etapa=${this.filtroEtapa}`);   
                
                    
                params.push(`exportarExcel=true`);                      
                    
                window.open(`/api/relatorio/RetornarAtrasados?${params.join('&')}`,'_blank');
                
            },              
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',data)
                    })
                    .catch((error) => {                        
                        throw error
                    });
                
            },                    

            async loadAsyncData(){    
                this.isLoading = true;                     
                await this.loadAtendimentos();                     
            },      
            loadAtendimentos(){

                const params = [                    
                    `unidadeId=${this.unidadeId}`                                                      
                ];
                
              
                if(this.vendedorFiltro>0)
                    params.push(`comercialId=${this.vendedorFiltro}`);    

                if(this.filtroEtapa)
                    params.push(`etapa=${this.filtroEtapa}`);                        
                    

                this.$http.get(`/api/relatorio/RetornarAtrasados?${params.join('&')}`)
                        .then(res => res.data)
                        .then(data => {   
                        this.listaAtrasados = data;
                    })
                    .catch((error) => {
                        this.listaAtrasados = []
                        throw error
                    })
                    .finally(() => {
                        this.isFetching = false;
                        this.isLoading = false;
                    })                

            },                                             
        },
        mounted(){               
            this.carregarVendedoresUnidade();            
            
            this.loadAsyncData();
            this.loadTotalNotificacoesFuncionario(); 
        },             
    }
</script>