<template>
    <section>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 0"
                    @open="isOpen = 0;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.PESSOAS')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column ">
                        <div v-if="isInRole('cliente-alterar') || isInRole('cliente-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.CLIENTES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'clientes', query: { procurar: procurar.paciente }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                        v-model="procurar.paciente"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'clientes' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('cliente-alterar')" :to="{ name: 'cliente' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>

                    <div class="column ">
                        <div v-if="isInRole('Administrador')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.FUNCIONARIO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'funcionarios', query: { procurar: procurar.funcionario }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.funcionario"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'funcionarios' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                  </router-link>
                                <router-link v-if="isInRole('Administrador')" :to="{ name: 'funcionario' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>                     
                     <div class="column ">
                        <div  v-if="isInRole('Administrador')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.NIVELACESSO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'niveisdeacesso', query: { procurar: procurar.niveldeacesso }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.niveldeacesso"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'niveisdeacesso' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>
                                <router-link :to="{ name: 'niveldeacesso' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>          
                    <div class="column ">
                        <div v-if="isInRole('arquiteto-alterar') || isInRole('arquiteto-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.ARQUITETO')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'arquiteto', query: { procurar: procurar.arquiteto }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.arquiteto"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'arquitetos' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('arquiteto-alterar')" :to="{ name: 'arquiteto' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>                     
                    <div class="column ">        
                    </div>                                                 
                </div>
                 
            </div>
        </b-collapse>
        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 1"
                    @open="isOpen = 1;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.EMPRESA')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="card-content is-paddingless">
                <div class="columns">
                    <div class="column">
                            <div v-if="isInRole('unidade-alterar') || isInRole('unidade-consultar')" class="panel">
                                <p class="panel-heading background-1">
                                    {{$t('MANUTENCAOLISTAR.UNIDADES')}}
                                </p>
                                <form @submit.stop.prevent="$router.push({ name: 'unidades', query: { procurar: procurar.unidade }})" method="get" class="panel-block">
                                    <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                                v-model="procurar.unidade"
                                                name="procurar" 
                                                icon="magnify"></b-input>
                                </form>
                                <div class="panel-block is-justified-between">
                                    <router-link :to="{ name: 'unidades' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                    </router-link>
                                    <router-link v-if="isInRole('unidade-alterar')" :to="{ name: 'unidade' }" class="button is-link is-fullwidth">
                                        {{$t('SISTEMA.INCLUIR')}}
                                    </router-link>
                                </div>
                            </div>
                        </div>      
                    <div class="column">
                        <div v-if="isInRole('fornecedor-alterar') || isInRole('fornecedor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.FORNECEDOR')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'fornecedores', query: { procurar: procurar.fornecedor }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.fornecedor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>                           
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'fornecedores' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('fornecedor-alterar')" :to="{ name: 'fornecedor' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">                        
                        <div v-if="isInRole('setor-alterar') || isInRole('setor-consultar')" class="panel">
                            <p class="panel-heading background-1">
                                {{$t('MANUTENCAOLISTAR.SETORES')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'setores', query: { procurar: procurar.setor }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.setor"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'setores' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('setor-alterar')" :to="{ name: 'setor' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="column">
                        <div v-if="isInRole('departamento-alterar') || isInRole('departamento-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.DEPARTAMENTOS')}}
                            </p>
                            <form @submit.stop.prevent="$router.push({ name: 'departamentos', query: { procurar: procurar.departamento }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.departamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'departamentos' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('departamento-alterar')" :to="{ name: 'departamento' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                    </div>                    
                </div>
            </div>
        </b-collapse>

        <b-collapse class="card has-background-transparent"
                    animation="slide"
                    :open="isOpen == 3"
                    @open="isOpen = 3;salvarCollapseIndex()">
            <div slot="trigger"
                 slot-scope="props"
                 class="card-header has-background-light"
                 role="button">
                <p class="card-header-title">
                    {{$t('MANUTENCAOLISTAR.OUTROS')}}
                </p>
                <a class="card-header-icon">
                    <b-icon v-show="props.open" icon="chevron-up"></b-icon>
                    <b-icon v-show="!props.open" icon="chevron-down"></b-icon>
                </a>
            </div>
            <div class="columns">

                <div class="column">
                    <div v-if="isInRole('feriado-alterar') || isInRole('feriado-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.FERIADO')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'feriados', query: { procurar: procurar.feriado }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.feriado"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'feriados' }" class="button is-light is-fullwidth">
                               {{$t('SISTEMA.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('feriado-alterar')" :to="{ name: 'feriado' }" class="button is-link is-fullwidth">
                                {{$t('SISTEMA.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                        <div v-if="isInRole('tipos-agendamento-alterar') || isInRole('tipos-agendamento-consultar')" class="panel">
                            <p class="panel-heading background-2">
                                {{$t('MANUTENCAOLISTAR.TIPOAGENDAMENTO')}}
                            </p>                                                
                            <form @submit.stop.prevent="$router.push({ name: 'tiposdeagendamento', query: { procurar: procurar.tiposdeAgendamento }})" method="get" class="panel-block">
                                <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.tiposdeAgendamento"
                                         name="procurar" 
                                         icon="magnify"></b-input>
                            </form>
                            <div class="panel-block is-justified-between">
                                <router-link :to="{ name: 'tiposdeagendamento' }" class="button is-light is-fullwidth">
                                    {{$t('SISTEMA.LISTARTODOS')}}
                                </router-link>
                                <router-link v-if="isInRole('tipos-agendamento-alterar')" :to="{ name: 'tipoagendamento' }" class="button is-link is-fullwidth">
                                    {{$t('SISTEMA.INCLUIR')}}
                                </router-link>
                            </div>
                        </div>
                </div>                  
                <div class="column">
                    <div v-if="isInRole('tipoanexo-alterar') || isInRole('tipoanexo-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            {{$t('MANUTENCAOLISTAR.TIPOSANEXOS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'tipoanexos', query: { procurar: procurar.tipoanexo }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.tipoanexo"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'tipoanexos' }" class="button is-light is-fullwidth">
                                {{$t('SISTEMA.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('tipoanexo-alterar')" :to="{ name: 'tipoanexo' }" class="button is-link is-fullwidth">
                                {{$t('SISTEMA.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-if="isInRole('modelodocumento-alterar') || isInRole('modelodocumento-consultar')" class="panel">
                        <p class="panel-heading background-2">
                            {{$t('MANUTENCAOLISTAR.MODELODOCUMENTOS')}}
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'modelodocumentos', query: { procurar: procurar.modelodocumento }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                         v-model="procurar.modelodocumento"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'modelodocumentos' }" class="button is-light is-fullwidth">
                                {{$t('SISTEMA.LISTARTODOS')}}
                            </router-link>
                            <router-link v-if="isInRole('modelodocumento-alterar')" :to="{ name: 'modelodocumento' }" class="button is-link is-fullwidth">
                                {{$t('SISTEMA.INCLUIR')}}
                            </router-link>
                        </div>
                    </div>
                </div>
               
               
            </div>
            <div class="columns">
                <div class="column ">
                    <div v-if="isInRole('motivo-alterar') || isInRole('motivo-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.MOTIVOS')}} 
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'motivos', query: { procurar: procurar.motivo }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                        v-model="procurar.motivo"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'motivos' }" class="button is-light is-fullwidth">
                                {{$t('SISTEMA.LISTARTODOS')}} 
                            </router-link>
                            <router-link v-if="isInRole('motivo-alterar')" :to="{ name: 'motivo' }" class="button is-link is-fullwidth">
                                {{$t('SISTEMA.INCLUIR')}} 
                            </router-link>
                        </div>
                    </div>                        
                </div>              
                <div class="column"> 
                    <div v-if="isInRole('prazo-alterar') || isInRole('prazo-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.PRAZOS')}} 
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'prazos', query: { procurar: procurar.motivo }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                        v-model="procurar.motivo"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'prazos' }" class="button is-light is-fullwidth">
                                {{$t('SISTEMA.LISTARTODOS')}} 
                            </router-link>
                            <router-link v-if="isInRole('prazo-alterar')" :to="{ name: 'prazo' }" class="button is-link is-fullwidth">
                                {{$t('SISTEMA.INCLUIR')}} 
                            </router-link>
                        </div>
                    </div>                          
                </div>  
                <div class="column"> 
                    <div v-if="isInRole('custo-alterar') || isInRole('custo-consultar')" class="panel">
                        <p class="panel-heading background-1">
                            {{$t('MANUTENCAOLISTAR.CUSTOS')}} 
                        </p>
                        <form @submit.stop.prevent="$router.push({ name: 'custos', query: { procurar: procurar.motivo }})" method="get" class="panel-block">
                            <b-input :placeholder="$t('SISTEMA.PROCURAR')"
                                        v-model="procurar.motivo"
                                        name="procurar" 
                                        icon="magnify"></b-input>
                        </form>
                        <div class="panel-block is-justified-between">
                            <router-link :to="{ name: 'custos' }" class="button is-light is-fullwidth">
                                {{$t('SISTEMA.LISTARTODOS')}} 
                            </router-link>
                            <router-link v-if="isInRole('custo-alterar')" :to="{ name: 'custo' }" class="button is-link is-fullwidth">
                                {{$t('SISTEMA.INCLUIR')}} 
                            </router-link>
                        </div>
                    </div>                          
                </div>  
                <div class="column"> </div>
            </div>


        

        </b-collapse>
    </section>
</template>

<script>
    import { mapState, mapGetters } from 'vuex'

    export default {
        data() {
            return {
                isOpen: localStorage.manutencaoCollapseIndex ?? 0,
                procurar: {}
            }
        },
        computed: {
            ...mapGetters([
                'isInRole'
            ]),
            ...mapState([
                'config',
                'integradores',
                'usuario'   
            ])
        },
        methods: {
            salvarCollapseIndex() {
                localStorage.manutencaoCollapseIndex = this.isOpen;
            },
            loadTotalNotificacoesFuncionario() {
                
                this.$http.get('/api/notificacao/retornaTotalNotificacoesFuncionario?funcionarioId=' + this.usuario.funcionarioId )
                        .then(res => res.data)
                        .then(data => {   
                        
                        this.$store.commit('componentes/SET_TOTAL_NOTIFICACAO',data)
                    })
                    .catch((error) => {                        
                        throw error
                    });
                
            },             
        },
        mounted() {                        
            this.loadTotalNotificacoesFuncionario();                                                                      
        }        
    }
</script>