<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-info">
            <p class="modal-card-title">{{$t('MODALOBSERVACAOGUIA.OBSERVACAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <div class="panel">

                <p class="panel-block">
                    <b-field :label="$t('MODALOBSERVACAOGUIA.NOTIFICARENVOLVIDOS')">
                    </b-field>
                </p>                
                <div class="panel-block">                                    
                    <b-switch  size="is-small" v-model="notificaEnvolvidos" @input="notificarEnvolvidos()"></b-switch>

                    <div v-if="notificaEnvolvidos">
                        <searchIdName class="column is-9" :id.sync="adicionaFuncionarioId" :nome.sync="adicionaFuncionarioNome" :trazDesativado="false" 
                                        :label="$t('SISTEMA.FUNCIONARIO')" table="FuncionariosUnidade"
                                        :camposAdicionais="'&unidadeId='+unidadeId" >
                                        <slot>
                                                <b-button icon-left="plus" type="is-primary" @click="addFuncionario()"></b-button>
                                            </slot>                                        
                        </searchIdName>
                        <table class="table is-fullwidth">
                            <tr  v-for="(item, index) in funcionarios" v-bind:key="index">
                                <td>{{item.nome}} </td>                                                                          
                                <td><b-button type="is-danger" size="is-small" @click="deleteFuncionario(index)" title="Remover Funcionario?" > <b-icon icon="delete" size="is-small"></b-icon> </b-button> </td>   
                            </tr>
                        </table> 
                    </div>                      
                </div>
                                
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                

            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-success"
                     :loading="salvandoDados"
                      @click="incluirObservacao">{{$t('SISTEMA.INCLUIR')}}</b-button>
            <b-button @click="$emit('close')">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    import searchIdName from '@/components/searchIdName.vue'      
    
    export default {
        props: {
            guia: Object                                            
        },
        data() {
            return {                              
                observacoes: null,
                salvandoDados :false,
                notificaEnvolvidos:false,
                funcionarios:[],
                adicionaFuncionarioId:null,
                adicionaFuncionarioNome:null
            }
        },
        components: {                       
            searchIdName                               
        },        
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {
            return true;
        },
        methods: {

            addFuncionario(){

                this.funcionarios.push({id: this.adicionaFuncionarioId, nome: this.adicionaFuncionarioNome})
                this.adicionaFuncionarioNome = null;
                this.adicionaFuncionarioId = '';            
                this.$forceUpdate();  

            },
            deleteFuncionario(index) {
                
                this.funcionarios.splice(index, 1);
                                
                this.$forceUpdate();                
            },                     
            notificarEnvolvidos(){
                if(this.notificaEnvolvidos){
                    this.$http.get('/api/atendimento/RetornarEnvolvidos?guiaId='+this.guia.id)
                .then(res => {
                    if (res.data != null) {
                        this.funcionarios = res.data
                    }                    
                });
                }else{
                    this.funcionarios = [];
                }

            },
        

            async incluirObservacao(){

                this.salvandoDados=true;
                

                if (this.observacoes== null  || this.observacoes == '' || this.observacoes == undefined) {
                    this.salvandoDados = false;
                    return;
                }      
                
                const guiaObservacao = {
                    guiaId: this.guia.id,
                    observacao : this.observacoes,
                    notificaEnvolvidos: this.notificaEnvolvidos,
                    funcionariosEnvolvidos : this.funcionarios
                }                
                this.isLoading = true;  
                
                try {
                    this.$http.post('/api/atendimento/guiaObservacoes', guiaObservacao)
                        .then(res => {
                            this.salvandoDados=false;
                            return true;

                        }).finally(() => {                                
                            this.salvandoDados=false;    
                            this.observacoes = null;

                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t('GUIAATENDIMENTO.MENSAGEMOBSERVACAOSALVA'),
                                type: 'is-success'
                            }); 
                                                                                                                  
                            this.$emit('close');                                                                                   
                        }); 
                        
                    } catch (e) {
                        this.salvandoDados=false;                        
                        console.error(e);
                    }                    
            },            
            
        }
    }
</script>