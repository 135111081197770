<template>
    <div class="modal-card">
        <header class="modal-card-head has-bg-danger">
            <p class="modal-card-title">{{$t('SISTEMA.ATENCAO')}}</p>
        </header>
        <section class="modal-card-body is-paddingless">
            <b-notification type="is-warning">                           
                <p>{{$t('MODALALTERARSTATUSGUIA.AVISO')}}</p>
            </b-notification>
            <div class="panel">
                <div class="panel-block">
                    <b-field expanded :label="$t('MODALALTERARSTATUSGUIA.FLUXO')">
                                                <b-input :disabled="true" type="text" v-model="fluxo"></b-input>
                    </b-field>               
                </div> 
                <div class="panel-block">
                    <b-field expanded :label="$t('MODALALTERARSTATUSGUIA.ETAPA')">
                        <b-select expanded v-model="etapaSelecionada">
                        <option v-for="(etapa) in listaEtapas" :key="etapa"
                                :value="etapa">
                            {{ etapa }}
                        </option>
                        </b-select>
                    </b-field>               
                </div>    
                
                <p class="panel-block">
                    <b-field :label="$t('SISTEMA.OBSERVACAO')">
                    </b-field>
                </p>
                <div class="panel-block">
                    <textarea class="textarea" v-model="observacoes" rows="4"></textarea>                                      
                </div>                   
                          
            </div>
        </section>
        <footer class="modal-card-foot">
            <b-button type="is-warning"
                     :loading="salvandoDados"
                      @click="confirmar">{{$t('MODALALTERARSTATUSGUIA.CONFIRMARDEVOLUCAO')}}</b-button>
            <b-button @click=" $emit('close'); ">{{$t('SISTEMA.CANCELAR')}}</b-button>
        </footer>
    </div>
</template>

<script>
    import moment from 'moment';
    import { mapState } from 'vuex'
    
    export default {
        props: {
            item: Object                                                                  
        },
        data() {
            return {                                                                
                salvandoDados :false,
                fluxo:null,
                listaEtapas: [],
                etapaSelecionada:null,
                observacoes:null
            }
        },
        computed: {
            ...mapState([                                
                'unidadeId',                
            ]),  
        },        

        mounted() {

            if(this.item.status == 5 ){                
                this.fluxo = 'PÓS VENDA';
            }else if (this.item.dataHoraVenda !=null || this.item.dataHoraEfetivacao !=null){
                this.fluxo = 'VENDA';
            }else{
                this.fluxo = 'ATENDIMENTO';
            }


            if(this.fluxo == 'VENDA'){
                this.listaEtapas = ['Revisão Efetivação','Validar Efetivação', 'Financeiro', 'Eletros', 'Assinatura de Projetos','Medidor', 'Conferência Em Espera', 'Conferência Em Execução','Envio Produção','Finalizar Efinance', 'Plantas', 'Entregas Futura'];

            }else if(this.fluxo == 'PÓS VENDA'){
                this.listaEtapas = ['Plantas Impressão','Obra não Conforme','Vistorias','Instalações Futuras','Entrega','Para Instalação','Instalação Incompleta'];

            }else if(this.fluxo == 'ATENDIMENTO'){
                this.listaEtapas = ['Prioridade a Definir', 'Projetos em Espera', 'Projetos em Andamento', 'Agendar Visita', 'Em Negociação'];
            }            

            return true;
        },
        methods: {

           
            async confirmar() {

                if(this.etapaSelecionada==null){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('MODALALTERARSTATUSGUIA.MENSAGEMETAPAOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                }

                if(this.observacoes ==null || (this.observacoes !=null && this.observacoes.length <20)){
                        
                        this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('SISTEMA.OBSERVACAOOBRIGATORIA'),
                        type: 'is-warning',
                        hasIcon: true
                        });                        
                        return true;
                }       


                this.salvandoDados=true;
                const params = [];                    
                params.push(`guiaId=${this.item.id}`);                 
                params.push(`fluxo=${this.fluxo}`); 
                params.push(`etapa=${this.etapaSelecionada}`);
                params.push(`observacoes=${this.observacoes}`);
                                                                                                                
                try {
                    this.$http.get(`/api/atendimento/AlterarStatusAtendimento?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {
                                return true;
                            }).finally(() => {      
                                this.salvandoDados=false;                                                                                
                                this.$emit('close');                                                                                                  
                            });                              
                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }                    
                         


               return true;
                

            },
            

        }
    }
</script>