<template>
    <section>
        <b-loading :active.sync="isLoading"></b-loading>
        <titlebar :title-stack="titleStack"></titlebar>
        <ValidationObserver ref="observer">
            <div class="box">
                <b-tabs type="is-boxed" @input="mudouTabPrincipal" v-model="tabsPrincipal" >
                    <b-tab-item value="atendimento" :label="$t('SISTEMA.ATENDIMENTO')" icon="account">
                        <div class="columns">                    
                        
                            <div class="column is-9">
                                <div class="columns">
        
                                    <div class="column is-3">
                                        <b-field :label="$t('GUIAATENDIMENTO.EMITIDA')">
                                            <b-input v-model="guia.dataHoraCadastroStr" readonly  disabled></b-input>
                                        </b-field>
                                    </div>
                                    <div class="column is-3" >
                                        <b-field :label="$t('GUIAATENDIMENTO.TEMPERATURA')">                            
                                            <b-select :disabled ="campoDisableTemperatura"  expanded :placeholder="$t('GUIAATENDIMENTO.TEMPERATURA')" v-model="guia.temperatura">
                                                <option value="1">FRIO</option>
                                                <option value="2">MORNO</option>
                                                <option value="3">QUENTE</option>
                                            </b-select>
                                        </b-field>                             
                                    </div>   

                                <div class="column is-3" >
                                        <b-field expanded :label="$t('GUIAATENDIMENTO.PRAZOCONCLUSAOVENDEDOR')">
                                            <b-input :disabled="campoDisabledPrazoVendedor" 
                                                type="number" max="20"  min="0" v-model="guia.prazoVendedorEmDias"></b-input>
                                        </b-field>
                                            
                                    </div> 
                                <div class="column is-3" >
                                        <b-field expanded :label="$t('GUIAATENDIMENTO.NUMEROAMBIENTES')">
                                            <b-input :disabled="true" 
                                                type="number" max="50"  min="0" v-model="guia.numeroAmbiente"></b-input>
                                        </b-field>
                                            
                                    </div>                                                                                    
                                    
                                </div>                        
                                <div class="columns">
                                    <searchIdName class="column is-9" :id.sync="guia.clienteCodigoExterno"  :codigoExterno="true"
                                    :trazDesativado="true" :label="$t('SISTEMA.CLIENTE')" table="Cliente" :camposAdicionais="'&unidadeId='+unidadeId" :leitura="true">
                                    </searchIdName>                                                           

                                </div>  
                                <div class="columns">
                                    <searchIdName class="column is-9" :id.sync="guia.funcionarioComercialId" :trazDesativado="false" 
                                    :label="$t('GUIAATENDIMENTO.VENDEDOR')" table="FuncionarioComercial"
                                    :camposAdicionais="'&unidadeId='+unidadeId" :leitura="(guia.funcionarioComercialId > 0 && guia.id>0)">
                                    </searchIdName>
                                    <b-switch class="mt-2" :disabled="guia.dataHoraVenda !=null" v-model="guia.inside"    >
                                        {{$t('SISTEMA.INSIDE')}}
                                    </b-switch>                                    
                                </div>  
                                <div class="columns">
                                    <searchIdName class="column is-9" :id.sync="guia.arquitetoId" :trazDesativado="false" 
                                    :label="$t('GUIAATENDIMENTO.ARQUITETO')" table="Arquiteto"
                                    :camposAdicionais="'&unidadeId='+unidadeId" >
                                        <slot>
                                            <b-button v-if="guia.id>0" icon-left="account-search"  @click="atualizarArquitetoElist()"                                                                                                        
                                                    :title="$t('GUIAATENDIMENTO.BUSCARARQUITETOELIST')"></b-button>
                                        </slot>                                    
                                    </searchIdName>
                                </div>                                    
                                <div class="columns" v-if="guia.dataHoraPrioridade !=null">
                                    <searchIdName class="column is-6" :id.sync="guia.funcionarioProjetistaId" :trazDesativado="false" 
                                    :label="$t('GUIAATENDIMENTO.PROJETISTA')" table="FuncionarioProjetista"
                                    :leitura="(!isInRole('atendimento-alterar-projetista') ||  this.guia.dataHoraVenda !=null)">
                                    </searchIdName>
                                <div class="column is-3" >
                                        <b-field v-if="guia.funcionarioProjetistaId != 51" expanded :label="$t('GUIAATENDIMENTO.PRAZOCONCLUSAOPROJETO')">
                                            <b-input :disabled="(!isInRole('atendimento-alterar-projetista') ||  this.guia.dataHoraVenda !=null)" 
                                                type="number" max="300"  min="0" v-model="guia.prazoProjetoEmDias"></b-input>
                                        </b-field>
                                            
                                    </div>                              
                                </div> 

                                <div class="columns" v-if="guia.dataHoraProjeto !=null">
                                    <div class="column is-1" ></div>
                                    <div class="column is-6" >
                                        <article  class="panel is-primary">
                                            <div class="panel-heading has-text-centered">
                                                {{$t('ATENDIMENTO.VENDASPARAEFETIVAR')}}
                                            </div>   
                                            <div class="panel-block is-justified-between">
                                                <b-radio v-model="negociacao"
                                                        size="is-small"
                                                        :disabled="guia.dataHoraCancelado !=null || guia.dataHoraVenda !=null"
                                                        native-value="venda"
                                                        @change.native="atualizaStatusGuia(); ">                               
                                                    <span>{{$t('ATENDIMENTO.VENDA')}}</span>
                                                </b-radio>

                                                <b-radio v-model="negociacao"
                                                        :disabled="guia.dataHoraCancelado !=null || guia.dataHoraVenda !=null"
                                                        size="is-small"
                                                        native-value="alteracao"
                                                        @change.native="atualizaStatusGuia();" >                                                                
                                                    <span>{{$t('ATENDIMENTO.ALTERACAO')}}</span>
                                                </b-radio>   

                                                <b-radio v-model="negociacao"
                                                        :disabled="guia.dataHoraCancelado !=null || guia.dataHoraVenda !=null"
                                                        size="is-small"
                                                        native-value="desistencia"
                                                        @change.native="atualizaStatusGuia();" >                                                                
                                                    <span>{{$t('ATENDIMENTO.DESISTENCIA')}}</span>
                                                </b-radio>    


                                                <b-radio v-model="negociacao"
                                                        :disabled="guia.dataHoraCancelado !=null || guia.dataHoraVenda !=null"
                                                        size="is-small"
                                                        native-value="emespera"
                                                        @change.native="atualizaStatusGuia();" >                                                                
                                                    <span>{{$t('ATENDIMENTO.EMESPERA')}}</span>
                                                </b-radio>                                                                                                                    
                                            </div>                                
                                        </article>                                   
                                    </div>
                                                                
                                </div>                                                                                                  
                
                    
                            </div>
                            <div class="column is-3">                        
                
                                <div class="columns"  >
                                    <div class="column is-12">
                                        <b-notification v-if="guia.dataHoraCancelado !=null" type="is-danger" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                            {{$t('GUIAATENDIMENTO.GUIACANCELADA').toUpperCase()}}
                                        </b-notification> 

                                        <b-notification v-if="guia.guiaVinculadaId > 0" type="is-info" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                            {{$t('GUIAATENDIMENTO.GUIAVINCULADA').toUpperCase()}}
                                                <router-link :to="{ name: 'guia', params: { id: guia.guiaVinculadaId, guiaList:true}}" class="button is-small" title="Abrir Atendimento Principal">
                                                    <b-icon icon="pencil" size="is-small"></b-icon>
                                                </router-link>                                                 
                                        </b-notification>                                         
                                                                                                    
                                        <div v-if="guia.id != 0" class="form-control-static">
                                            
                                            <div class="buttons has-addons is-centered m-0 "> 
                                                <b-button :title="$t('GUIAATENDIMENTO.NOTIFICARGUIA')"   
                                                @click="confirmarNotificacao"                                   
                                                icon-left="email"></b-button>                                                                                                           
                                                <b-button v-if="guia.dataHoraCancelado ==null && isInRole('atendimento-cancelar')"  
                                                @click="confirmarCancelamento(false)" 
                                                :title="$t('GUIAATENDIMENTO.CANCELARATENDIMENTO')"                                                                                        
                                                icon-left="close-circle" type="has-text-danger"></b-button>
                                                <b-button v-if="guia.dataHoraCancelado ==null && isInRole('atendimento-trocarvendedor')"  
                                                @click="confirmarTrocaVendedor()" 
                                                :title="$t('GUIAATENDIMENTO.TROCARVENDEDOR')"                                                                                        
                                                icon-left="account-switch" ></b-button>                                                
                                                <b-button v-if="guia.dataHoraCancelado ==null "  @click="confirmarInclusaoObservacao()" 
                                                :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')"                                                                                        
                                                icon-left="comment"></b-button>                                                 
                                                <b-button v-if="guia.dataHoraCancelado !=null && isInRole('atendimento-cancelar')"  @click="confirmarReativamento()" 
                                                :title="$t('GUIAATENDIMENTO.REATIVARATENDIMENTO')"                                                                                        
                                                icon-left="refresh" type="has-text-success"></b-button> 
                                                                                    
                                            </div>                                                                                
                                            
                                            <article v-if="guia.funcionarioAutorizacaoNome !=null && habilitarAlteracaoGuia()" class="panel">
                                                <b-field>
                                                    <b-switch :disabled="guia.autorizacao" v-model="guia.autorizacao" @input="confirmarAutorizacaoProjeto()" type="is-success">
                                                        {{$t('GUIAATENDIMENTO.AUTORIZACAO')}}
                                                    </b-switch>                                            
                                                </b-field>                                                
                                            </article> 

                                            <article v-if="guia.funcionarioAutorizacaoNome !=null && habilitarAlteracaoGuia()"  class="panel">
                                                <b-field>
                                                    <b-switch :disabled="guia.dataHoraProjeto !=null" v-model="guia.projeto" @input="confirmarFinalizacaoProjeto()" type="is-success">
                                                        {{$t('GUIAATENDIMENTO.CONCLUSAOPROJETO')}}
                                                    </b-switch>                                            
                                                </b-field>       
                                                <b-field  v-if="guia.dataHoraProjeto ==null">
                                                    <b-switch :disabled="guia.dataHoraProjeto !=null" v-model="guia.devolver" @input="confirmarDevolucaoProjeto()" type="is-success">
                                                        {{$t('GUIAATENDIMENTO.DEVOLVERPROJETO')}}
                                                    </b-switch>                                            
                                                </b-field>                                                                                
                                            </article>  

                                            <article v-if="( (guia.dataHoraProjeto !=null && guia.dataHoraVenda ==null) || guia.possuiAlteracao )&& habilitarAlteracaoGuia()" class="panel">
                                                <div class="panel-heading has-text-centered">
                                                    {{$t('ATENDIMENTO.AGENDAMENTOCLIENTE')}}
                                                </div>   
                                                                             
                                                <div v-for="(agendamento, index) in agendamentos" v-bind:key="index">
                                                    <a v-if="(agendamento.agendamentoAutomatico==false)" class="panel-block">
                                                        <span class="panel-icon block">                                            
                                                            <b-icon :style="tipoAgendamentoStyle(agendamento)"  size="is-small" icon="square-rounded"></b-icon>                                            
                                                        </span>                                        
                                                        <p> {{agendamento.tipoAgendamentoNome}} - {{ agendamento.dataHora }}</p>
                                                    </a>
                                                </div>                                                                             
                                                <div class="panel-block">                                    
                                                    <b-button expanded class="button is-info" @click="abrirCalendario(1,null,null,'atendimento')" > {{$t('ATENDIMENTO.ABRIRCALENDARIO')}} </b-button>                                            
                                                </div>
                                            </article>   
                                                                                                                                                                                
                                        </div>
                                    </div> 
                                                                                                            
                                </div>

                    
                            </div>
                                
                        </div>
                    </b-tab-item>                    

                    <b-tab-item value="venda" v-if="this.guia.dataHoraVenda !=null || fluxoVenda"  :label="$t('SISTEMA.VENDAS')" icon="currency-usd">
                            <div class="columns">    
                                <div class="column is-9">
                                    
                                                                        
                                    <div class="columns">
                                        <searchIdName class="column is-9" :id.sync="guia.clienteCodigoExterno"  :codigoExterno="true"
                                        :trazDesativado="true" :label="$t('SISTEMA.CLIENTE')" table="Cliente" :camposAdicionais="'&unidadeId='+unidadeId" :leitura="true">
                                            <slot>
                                                <b-button icon-left="square-edit-outline"
                                                        tag="router-link"
                                                        :to="{ name: 'cliente', params: { id: guia.clienteCodigoExterno, codigoExterno: true }}"
                                                        :title="$t('GUIAATENDIMENTO.INFOCLIENTE')"></b-button>
                                            </slot>
                                        </searchIdName>                       

                                    </div>  

                                    <div class="columns" v-if="venda && venda.dataHoraMedidor !=null">
                                        <searchIdName class="column is-6" :id.sync="venda.funcionarioConferenteId" :trazDesativado="false" 
                                        :label="$t('GUIAATENDIMENTO.CONFERENTE')" table="FuncionarioConferente" 
                                        :leitura="(!isInRole('vendas-alterar-conferente') )">
                                        </searchIdName>

                                        <div class="column is-3" >
                                            <b-field expanded :label="$t('GUIAATENDIMENTO.PRAZOCONCLUSAOCONFERENCIA')">
                                                <b-input :disabled="(!isInRole('vendas-alterar-conferente') ||   this.venda.dataHoraConferenciaAutorizada !=null)" 
                                                    type="number" max="300"  min="0" v-model="venda.prazoConferenciaEmDias"></b-input>
                                            </b-field>                                                
                                        </div>                              
                                    </div>

                                    <div class="columns" v-if="venda && venda.dataHoraConferenciaConcluida !=null">
                                        <div class="column is-9">    
                                            <b-field expanded :label="$t('GUIAATENDIMENTO.ELIST')">
                                                <textarea class="textarea" v-model="venda.elist" rows="2"></textarea>
                                            </b-field>  
                                            
                                        </div>
                                                                                                                        
                                    </div> 
                                    
                                
                                    <div class="columns" v-if="venda && venda.assinaturaDigital && venda.dataHoraEletro !=null && venda.dataHoraAssinatura ==null && venda.status==0  && habilitarAlteracaoGuia()" >
                                        <div class="column is-9" >
                                            <article  class="panel">
                                                <div class="panel-heading has-text-centered">
                                                    {{$t('ATENDIMENTO.ASSINATURADIGITAL')}}
                                                    <b-button 
                                                            class="mt-0"
                                                            style=" float: right; margin-top: -5px !important;"
                                                            icon-left="refresh"
                                                            type="is-info"
                                                            size="is-small"
                                                            :title="$t('ATENDIMENTO.ASSINATURADIGITALATUALIZAR')"
                                                            @click="atualizarAssinaturaDigital()"></b-button>                                                         
                                                </div>   
                                                <table v-if="anexos!=null && anexos.length>0" style="width: 100%;" class="table is-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Tipo</th>                        
                                                            <th>Nome</th>
                                                            <th>Ação</th>
                                                        </tr>                                                
                                                    </thead>
                                                    <tbody>
                                                        
                                                        <tr :class="anexo.documentoDigital ? 'has-background-info-light' : '' " v-for="(anexo, index) in anexos.filter(x=>x.documentoDigital)" :key="index">
                                                            <td> {{anexo.tipoAnexoNome ?? 'NAO DEFINIDO'}} </td>
                                                            <td >                                        
                                                                <p>                                                                    
                                                                    <a @click="visualizarAnexoDigital(anexo.id)" class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>
                                                                </p>
                                                            </td>
                                                            
                                                            <td v-if="anexo.id ">        
                                                                <div class="is-inline-flex">

                                                                        <b-button 
                                                                            class="ml-2"
                                                                            icon-left="delete"
                                                                            type="is-danger"
                                                                            size="is-small"
                                                                            :title="$t('ANEXOS.REMOVERANEXO')"
                                                                            @click="removerAnexoDigital(anexo.id)"></b-button>                                                                                                                                    
                                                                    
                                                                    

                                                                </div>                                   
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>   
                                                <table v-if="venda && venda.signatarios!=null && venda.signatarios.length>0" style="width: 100%;" class="table is-striped">
                                                    <thead>
                                                        <tr>
                                                            <th>Email</th>                        
                                                            <th>Ação</th>
                                                            <th>Assinado?</th>
                                                        </tr>                                                
                                                    </thead>
                                                    <tbody>
                                                        
                                                        <tr :class="'has-background-info-light'" v-for="(signatario, index) in venda.signatarios" :key="index">
                                                            <td>{{ signatario.email}} </td>
                                                            <td > 
                                                                {{ signatario.acaoNome}}
                                                                <a href="#" class="has-text-info" @click="gerarLinkAssinaturaDigital(signatario.id)" :title="$t('ATENDIMENTO.ASSINATURADIGITALLINK')">
                                                                    <b-icon  icon="link" size="is-small"></b-icon>
                                                                </a>                                                                
                                                            </td>
                                                            <td > 
                                                                <b-switch :disabled="true" v-model="signatario.assinado"  type="is-success">
                                                                    Assinou
                                                                </b-switch> 
                                                                <span v-if="signatario.assinado">
                                                                    <br>
                                                                    <small class="has-text-success">
                                                                        <b>{{$moment(signatario.dataHoraAssinatura).format("DD/MM/YYYY HH:mm:ss") }} </b>                                                       
                                                                    </small>
                                                                </span>                                                                                                                                  
                                                            </td>
                                                       
                                                        </tr>

                                                    </tbody>
                                                </table>                                                                                             
                                                <div v-if="venda && venda.signatarios!=null && venda.signatarios.filter(x=>!x.assinado).length==0" class="panel-block">
                                                    <button type="button" class="button is-success is-fullwidth" @click="confirmarConclusaoAssinaturaDigital()">
                                                        <b-icon icon="check"></b-icon>   &nbsp;&nbsp;&nbsp; {{$t('ATENDIMENTO.ASSINATURADIGITALCONCLUIDO')}}
                                                    </button>
                                                </div>                                                                                
                                            </article>                                   
                                        </div>                                        
                                    </div>                                    
                                                                 
                                                    
                                </div>
                                <div class="column is-3">                        
                    
                                    <div class="columns"  >
                                        <div class="column is-12">                                      
                                            <div v-if="guia.id != 0" class="form-control-static">                
                                            <b-notification v-if="guia.dataHoraCancelado !=null" type="is-danger" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                                {{$t('GUIAATENDIMENTO.GUIACANCELADA').toUpperCase()}}
                                            </b-notification> 

                                            <b-notification v-if="guia.guiaVinculadaId > 0" type="is-info" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                                {{$t('GUIAATENDIMENTO.GUIAVINCULADA').toUpperCase()}}
                                                <router-link :to="{ name: 'guia', params: { id: guia.guiaVinculadaId, guiaList:true}}" class="button is-small" title="Abrir Atendimento Principal">
                                                    <b-icon icon="pencil" size="is-small"></b-icon>
                                                </router-link>                                                  
                                            </b-notification>   
                                            <div class="buttons has-addons is-centered m-0 "> 
                                                <b-button :title="$t('GUIAATENDIMENTO.NOTIFICARGUIA')"   
                                                @click="confirmarNotificacao"                                   
                                                icon-left="email"></b-button>  
                                                <b-button v-if="guia.dataHoraCancelado ==null && isInRole('atendimento-cancelar') "  
                                                @click="confirmarCancelamento(false)" 
                                                :title="$t('GUIAATENDIMENTO.CANCELARATENDIMENTO')"                                                                                        
                                                icon-left="close-circle" type="has-text-danger"></b-button>     
                                                <b-button v-if="guia.dataHoraCancelado ==null && isInRole('atendimento-trocarvendedor')"  
                                                @click="confirmarTrocaVendedor()" 
                                                :title="$t('GUIAATENDIMENTO.TROCARVENDEDOR')"                                                                                        
                                                icon-left="account-switch" ></b-button>                                                                                                                                                                                                                                                    
                                                <b-button v-if="guia.dataHoraCancelado ==null "  @click="confirmarInclusaoObservacao()" 
                                                :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')"                                                                                        
                                                icon-left="comment"></b-button>  
                                                <b-button v-if="guia.dataHoraCancelado !=null && isInRole('atendimento-cancelar')"  @click="confirmarReativamento()" 
                                                :title="$t('GUIAATENDIMENTO.REATIVARATENDIMENTO')"                                                                                        
                                                icon-left="refresh" type="has-text-success"></b-button>                                                                                                                                                                                            
                                            </div> 
                                            
                                            <article v-if="venda && venda.status==2 && habilitarAlteracaoGuia() && emVistoriaMedicao"  class="panel">
                                                    <b-field>
                                                        <b-switch v-model="venda.vistoriaMedicaoConcluida" @input="confirmarConcluirVistoriaMedicao()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.VISTORIAMEDICAOCONCLUIDA')}}
                                                        </b-switch>                                            
                                                    </b-field>    
                                                    <b-field>
                                                        <b-switch v-model="venda.devolverconferenteVistoria" @input="devolverconferenteVistoriaMedicao()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVERCONFERENTEVISTORIA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                                        
                                            </article>                                            

                                            <article v-if="venda && venda.status==2 && habilitarAlteracaoGuia() && venda.dataHoraConferenciaAutorizada==null"  class="panel">
                                                <span v-if="!emVistoriaMedicao  && venda.dataHoraVistoriaMedicao==null">
                                                    <b-field>
                                                        <b-switch v-model="venda.removerDataEntregaFutura" @input="confirmarRemoverDataEntregaFuturaVenda()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.REMOVERDATAENTREGAFUTURA')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field>
                                                        <b-switch v-model="venda.removerDataEntregaFuturaPlantas" @input="confirmarRemoverDataEntregaFuturaPlantasVenda()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.REMOVERDATAENTREGAFUTURAPLANTAS')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                       
                                                </span>                                                
                                                    <b-field>
                                                        <b-switch  v-model="venda.prazoEntregaFutura" @input="confirmarPrazoEntregaFuturaVenda()" type="is-success">
                                                            {{$t('MODALPRAZOENTREGAFUTURA.NOVOPRAZO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     
                                                                                                                                                                                                                    
                                            </article>                                     

                                                <article v-if="venda && venda.dataHoraRevisao ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch v-model="venda.revisado" @input="confirmaRevisaoVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.REVISAOVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                               
                                                </article>
                                                <article v-if="venda && venda.dataHoraRevisao !=null && venda.dataHoraValidacao ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraValidacao !=null" v-model="venda.validado" @input="confirmarValidacaoVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.VALIDACAOVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>       
                                                    <b-field  v-if="guia.dataHoraValidacao ==null && venda.status==0">
                                                        <b-switch :disabled="guia.dataHoraValidacao !=null" v-model="venda.devolver" @input="confirmarDevolucaoEfetivacaoVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVEREFETIVACAO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                 
                                                </article> 
                                                <article v-if="venda && venda.dataHoraValidacao !=null && venda.dataHoraFinanceiro ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraFinanceiro !=null" v-model="venda.financeiro" @input="confirmarFinanceiroVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.FINANCEIROVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>       
                                                    <b-field  v-if="guia.dataHoraFinanceiro ==null && venda.status==0">
                                                        <b-switch :disabled="guia.dataHoraFinanceiro !=null" v-model="venda.devolverFinanceiro" @input="confirmarDevolucaoFinanceiroVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVERGERENTE')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                 
                                                </article>
                                                <article v-if="venda && venda.dataHoraFinanceiro !=null && venda.dataHoraEletro ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraEletro !=null" v-model="venda.eletro" @input="confirmarEletrosVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ELETROSVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraEletro !=null" v-model="venda.eletroAnexo" @input="eletrosAnexoConcluidaVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ELETROSVENDAANEXO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                      
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraEletro !=null && !venda.eletro" v-model="venda.prazoentregaeletro" @input="confirmarPrazoEntregaEletro()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.PRAZOENTREGAELETRO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                                                                      
                                                </article> 


                                                <article v-if="venda && !venda.assinaturaDigital && venda.dataHoraEletro !=null && venda.dataHoraAssinatura ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch v-if="venda.dataHoraAssinaturaGerente == null && !venda.uploadProjeto" :disabled="venda.dataHoraAssinatura !=null " v-model="venda.anexarProjeto" @input="anexarProjetoVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.UPLOADPROJETO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     
                                                    <b-field>
                                                        <b-switch v-if="venda.dataHoraAssinaturaGerente == null && isInRole('vendas-assinatura-gerente') && venda.uploadProjeto" :disabled="venda.dataHoraAssinatura !=null " v-model="venda.assinaturaGerente" @input="assinaturaConcluidaGerenteVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ASSINADOGERENTEVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field>
                                                        <b-switch v-if="venda.dataHoraAssinaturaGerente != null" :disabled="venda.dataHoraAssinatura !=null" v-model="venda.assinatura" @input="assinaturaConcluidaVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ASSINADOVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field>
                                                        <b-switch v-if="venda.dataHoraAssinaturaGerente != null" :disabled="venda.dataHoraAssinatura !=null" v-model="venda.enviarAssinaturaDigital" @input="assinaturaDigitalConcluidaVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ASSINADODIGITALVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                                                                        
                                                    <b-field>
                                                        <b-switch  :disabled="venda.dataHoraAssinatura !=null" v-model="venda.prazoAssinatura" @input="confirmarPrazoAssinaturaVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.PRAZOASSINATURA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                       
                                                    <b-field  v-if="guia.guiaVinculadaId <=0" >
                                                        <b-switch :disabled="guia.dataHoraAssinatura !=null" v-model="venda.alteracaoAssinatura" @input="confirmarAlteracaoProjeto(true)"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ALTERACAOPROJETOVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                                                                       
                                                </article>  
                                                <article v-if="venda && venda.dataHoraAssinatura !=null && venda.dataHoraMedidor ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraMedidor !=null" v-model="venda.medidor" @input="confirmarMedidorVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.MEDIDORTOTALVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>  
                                                    <b-field v-if="venda ">
                                                        <b-switch :disabled="venda.dataHoraMedidor !=null" v-model="venda.medidorparcial" @input="confirmarMedidorParcialVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.MEDIDORPARCIALVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>  
                                                    <b-field  v-if="venda ">
                                                        <b-switch  v-model="venda.devolverAssinatura" @input="confirmarDevolucaoAssinaturaVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVERASSINATURA')}}
                                                        </b-switch>                                            
                                                    </b-field>   
                                                    <b-field  v-if="venda ">
                                                        <b-switch  v-model="venda.transformaentregafutura" @input="confirmartransformaentregafutura()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.TRANSFORMAENTREGAFUTURA')}}
                                                        </b-switch>                                            
                                                    </b-field>  
                                                    <b-field  v-if="venda ">
                                                        <b-switch  v-model="venda.transformaentregafuturaplantas" @input="confirmartransformaentregafuturaplantas()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.TRANSFORMAENTREGAFUTURAPLANTAS')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                             
                                                                                                                                                                                                                                                                                                    
                                                </article>  

                                                <article v-if="venda && venda.dataHoraAssinatura !=null && venda.dataHoraMedidor ==null && (venda.status==0 || venda.status==2) && habilitarAlteracaoGuia()" class="panel">
                                                    <div class="panel-heading has-text-centered">
                                                        {{$t('ATENDIMENTO.AGENDAMENTOMEDIDOR')}}
                                                    </div>   
    
                                                    <div v-for="(agendamento, index) in agendamentosMedidor" v-bind:key="index">
                                                        <a v-if="(agendamento.agendamentoAutomatico==false)" class="panel-block">
                                                            <span class="panel-icon block">                                            
                                                                <b-icon :style="tipoAgendamentoStyle(agendamento)"  size="is-small" icon="square-rounded"></b-icon>                                            
                                                            </span>                                        
                                                            <p> {{agendamento.tipoAgendamentoNome}} - {{ agendamento.dataHora }}</p>
                                                        </a>
                                                    </div>                                                                             
                                                    <div class="panel-block">                                    
                                                        <b-button expanded class="button is-info" @click="abrirCalendario(2,null,null,'venda')" > {{$t('ATENDIMENTO.ABRIRCALENDARIO')}} </b-button>                                            
                                                    </div>
                                                </article> 
                                                <article v-if="venda && venda.dataHoraMedidor !=null && venda.dataHoraConferenciaAutorizada ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field  v-if="venda.dataHoraConferenciaAutorizada ==null && venda.status==0">
                                                            <b-switch :disabled="guia.dataHoraConferenciaAutorizada !=null" v-model="venda.devolverAssinatura" @input="confirmarDevolucaoAssinaturaVenda()" type="is-success">
                                                                {{$t('GUIAATENDIMENTO.DEVOLVERASSINATURA')}}
                                                            </b-switch>                                            
                                                    </b-field>  
                                                    <b-field  v-if="venda.dataHoraConferenciaAutorizada ==null && venda.status==0">
                                                            <b-switch :disabled="guia.dataHoraConferenciaAutorizada !=null" v-model="venda.devolverEletros" @input="confirmarDevolucaoEletrosVenda()" type="is-success">
                                                                {{$t('GUIAATENDIMENTO.DEVOLVERELETROS')}}
                                                            </b-switch>                                            
                                                    </b-field> 
                                                    <b-field  v-if="venda.dataHoraConferenciaAutorizada ==null && venda.status==0">
                                                            <b-switch :disabled="guia.dataHoraConferenciaAutorizada !=null" v-model="venda.devolverMedidor" @input="confirmarDevolucaoMedidorVenda()" type="is-success">
                                                                {{$t('GUIAATENDIMENTO.DEVOLVERMEDIDOR')}}
                                                            </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                                                                                                                        
                                                </article>                                                                                                          
                                                <article v-if="venda && venda.dataHoraConferenciaAutorizada !=null && venda.dataHoraConferenciaConcluida ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraConferenciaConcluida !=null" v-model="venda.conferenciaConcluida" @input="conferenciaConcluidaDeVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.CONFERENCIACONCLUIDAVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field>
                                                        <b-switch  :disabled="venda.dataHoraConferenciaConcluida !=null" v-model="venda.conferenciaConcluidaParcial" @input="confirmarConferenciaConcluidaParcialVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.CONFERENCIACONCLUIDAPARCIALVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     
                                                    <b-field  v-if="venda.dataHoraConferenciaConcluida ==null && venda.status==0">
                                                            <b-switch :disabled="guia.dataHoraConferenciaConcluida !=null" v-model="venda.devolverGerenteConferencia" @input="confirmarDevolucaoGerenteConferenciaVenda()" type="is-success">
                                                                {{$t('GUIAATENDIMENTO.DEVOLVERGERENTECONFERENCIA')}}
                                                            </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                                                                                                                        
                                                </article>  
                                                <article v-if="venda && venda.dataHoraVistoriaMedicao !=null && venda.dataHoraConferenciaAutorizada !=null &&  venda.status==2 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch  v-model="venda.plantasConcluido" @input="concluidoPlantasEntregaFuturaVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.PLANTASCONCLUIDO')}}
                                                        </b-switch>                                            
                                                    </b-field>
                                                    <b-field>
                                                        <b-switch  v-model="venda.prazoEntregaFutura" @input="confirmarPrazoEntregaFuturaVenda()" type="is-success">
                                                            {{$t('MODALPRAZOENTREGAFUTURA.NOVOPRAZO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                                                                                                                                                                              
                                                </article>                                                
                                                <article v-if="venda && venda.dataHoraConferenciaConcluida !=null && venda.dataHoraEnvioProducao ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraEnvioProducao !=null" v-model="venda.envioProducao" @input="confirmarEnvioProducaoVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ENVIOPRODUCAOEVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraEnvioProducao !=null" v-model="venda.devolverConferencia" @input="devolucaoConferenciaVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVERCONFERENCIA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     
                                                                                                                                                                                                                                                    
                                                </article>  
                                                <article v-if="venda && venda.dataHoraEnvioProducao !=null && venda.dataHoraFinalizarEfinance ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraFinalizarEfinance !=null" v-model="venda.finalizarEfinance" @input="confirmarFinalizarEfinanceVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.FINALIZAREFINANCEVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                  
                                                </article>          
                                                <article v-if="venda && venda.dataHoraFinalizarEfinance !=null && venda.dataHoraPlantas ==null && venda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field>
                                                        <b-switch :disabled="venda.dataHoraPlantas !=null" v-model="venda.plantasok"  @input="checkListDeVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.PLANTASVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                                                                                                  
                                                </article>                                                                                                                                                                                                                                                                                                  


                                            </div>
                                        </div>                                                                                                                 
                                    </div>
                                
                                    <div class="columns"   >
                                        <div class="column is-12">  

                                            <b-dropdown expanded rounded aria-role="list" >
                                                <button class="button is-fullwidth" slot="trigger" slot-scope="{ active }">
                                                    <span>{{$t('GUIAATENDIMENTO.DOCUMENTOS')}}</span>
                                                    <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                                </button>

                                                <b-dropdown-item   aria-role="listitem" >
                                                    <span class="columns">
                                                        <a class="column is-12" @click="documentoDeVenda()" style="color: #4a4a4a; text-decoration: none;" >                                                            
                                                            {{$t('GUIAATENDIMENTO.RESUMOVENDA')}}                                                            
                                                        </a>
                                                    </span>
                                                </b-dropdown-item>

                                                <b-dropdown-item v-if="(venda && venda.dataHoraFinalizarEfinance !=null && venda.dataHoraPlantas ==null && venda.status==0 ) || ( venda && venda.status==1 ) "  aria-role="listitem" >
                                                    <span class="columns">
                                                        <a class="column is-12" @click="checkListDeVenda()" style="color: #4a4a4a; text-decoration: none;" >                                                            
                                                            {{$t('GUIAATENDIMENTO.CHECKLISTVENDA')}}                                                            
                                                        </a>
                                                    </span>
                                                </b-dropdown-item>                                                

                                            </b-dropdown>

                                        </div>
                                    </div>                                    
                        
                                </div>
                            </div>                        
                    </b-tab-item>
                    <b-tab-item value="venda" v-else-if="guia && guia.dataHoraEfetivacao !=null "  :label="$t('SISTEMA.VENDAS')" icon="currency-usd">
                        <div class="columns">   
                            <div class="column is-9">
                            </div>
                            <div class="column is-3">                        
                        
                                <div class="columns"   >
                                    <div class="column is-12">  

                                        <b-dropdown expanded rounded aria-role="list" >
                                            <button class="button is-fullwidth" slot="trigger" slot-scope="{ active }">
                                                <span>{{$t('GUIAATENDIMENTO.DOCUMENTOS')}}</span>
                                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                            </button>

                                            <b-dropdown-item   aria-role="listitem" >
                                                <span class="columns">
                                                    <a class="column is-12" @click="documentoDeVenda()" style="color: #4a4a4a; text-decoration: none;" >                                                            
                                                        {{$t('GUIAATENDIMENTO.RESUMOVENDA')}}                                                            
                                                    </a>
                                                </span>
                                            </b-dropdown-item>

                                            <b-dropdown-item v-if="(venda && venda.dataHoraFinalizarEfinance !=null && venda.dataHoraPlantas ==null && venda.status==0 ) || ( venda && venda.status==1 ) "  aria-role="listitem" >
                                                <span class="columns">
                                                    <a class="column is-12" @click="checkListDeVenda()" style="color: #4a4a4a; text-decoration: none;" >                                                            
                                                        {{$t('GUIAATENDIMENTO.CHECKLISTVENDA')}}                                                            
                                                    </a>
                                                </span>
                                            </b-dropdown-item>                                                

                                        </b-dropdown>

                                    </div>
                                </div>                                    
        
                            </div>                            
                         </div>
                    </b-tab-item>
                    <b-tab-item value="posvenda" v-if="this.guia.dataHoraPosVenda !=null || fluxoPosVenda"   :label="$t('SISTEMA.POSVENDA')" icon="archive-outline">
                            <div class="columns">    
                                <div class="column is-9">
                                    <div class="columns">
                                        <searchIdName class="column is-9" :id.sync="guia.clienteCodigoExterno"  :codigoExterno="true"
                                        :trazDesativado="true" :label="$t('SISTEMA.CLIENTE')" table="Cliente" :camposAdicionais="'&unidadeId='+unidadeId" :leitura="true">
                                            <slot>
                                                <b-button icon-left="square-edit-outline"
                                                        tag="router-link"
                                                        :to="{ name: 'cliente', params: { id: guia.clienteCodigoExterno, codigoExterno: true }}"
                                                        :title="$t('GUIAATENDIMENTO.INFOCLIENTE')"></b-button>
                                            </slot>
                                        </searchIdName>                       
                                    </div>                                    
                                    <div v-if="posvenda" class="column is-9">    
                                        <b-field expanded :label="$t('GUIAATENDIMENTO.ELIST')">
                                            <textarea :disabled="true" class="textarea" v-model="posvenda.elist" rows="2"></textarea>
                                        </b-field>  
                                        
                                    </div>                                                                                                         
                                </div>
                                <div class="column is-3">                        
                    
                                    <div class="columns"  >
                                        <div class="column is-12">                                      
                                            <div v-if="guia.id != 0" class="form-control-static">                
                                                <b-notification v-if="guia.dataHoraCancelado !=null" type="is-danger" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                                    {{$t('GUIAATENDIMENTO.GUIACANCELADA').toUpperCase()}}
                                                </b-notification> 

                                                <b-notification v-if="guia.guiaVinculadaId > 0" type="is-info" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                                    {{$t('GUIAATENDIMENTO.GUIAVINCULADA').toUpperCase()}}
                                                    <router-link :to="{ name: 'guia', params: { id: guia.guiaVinculadaId, guiaList:true}}" class="button is-small" title="Abrir Atendimento Principal">
                                                        <b-icon icon="pencil" size="is-small"></b-icon>
                                                    </router-link>                                                         
                                                </b-notification>                                                    

                                                
                                                <div class="buttons has-addons is-centered m-0 "> 
                                                    <b-button :title="$t('GUIAATENDIMENTO.NOTIFICARGUIA')"   
                                                    @click="confirmarNotificacao"                                   
                                                    icon-left="email"></b-button>       
                                                    <b-button v-if="guia.dataHoraCancelado ==null && isInRole('atendimento-cancelar') "  
                                                    @click="confirmarCancelamento(false)" 
                                                    :title="$t('GUIAATENDIMENTO.CANCELARATENDIMENTO')"                                                                                        
                                                    icon-left="close-circle" type="has-text-danger"></b-button>                                                                                                                                                                                                       
                                                    <b-button v-if="guia.dataHoraCancelado ==null "  @click="confirmarInclusaoObservacao()" 
                                                    :title="$t('GUIAATENDIMENTO.INCLUIROBSERVACAO')"                                                                                        
                                                    icon-left="comment"></b-button>                    
                                                    <b-button v-if="guia.dataHoraCancelado !=null && isInRole('atendimento-cancelar')"  @click="confirmarReativamento()" 
                                                    :title="$t('GUIAATENDIMENTO.REATIVARATENDIMENTO')"                                                                                        
                                                    icon-left="refresh" type="has-text-success"></b-button>                                                                                                                                                                              
                                                </div>                                                                                                                                                                   


                                                <article v-if="posvenda && posvenda.status==0 && this.guiaList && isInRole('posvendas-alteracao-executivo') "  class="panel">
                                                    <b-field >
                                                        <b-switch v-model="posvenda.alterarExecutivo" @input="confirmarObraNaoConformeVendedorPosVenda()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ALTERAREXECUTIVO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                               
                                                </article>                                                  
                                                <article v-if="posvenda && posvenda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field  v-if="posvenda.dataHoraObrasPlantas ==null">
                                                        <b-switch v-model="posvenda.confirmarObrasPlantas" @input="confirmarObrasPlantas()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.CONCLUIROBRASPLANTAS')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field  v-if="posvenda.dataHoraObrasPlantas !=null && posvenda.dataHoraPlantasInstalacao ==null">
                                                        <b-switch v-model="posvenda.confirmarPlantasTecnicas" @input="confirmarPlantasTecnicas()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.CONCLUIRPLANTASTECNICAS')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     
                                                    <b-field v-if="posvenda.dataHoraObrasPlantas ==null  ">
                                                        <b-switch v-model="posvenda.devolverPlantas" @input="confirmarDevolucaoPlantasVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVERPLANTAS')}}
                                                        </b-switch>                                            
                                                    </b-field> 
                                                    <b-field v-if="posvenda.dataHoraObrasPlantas!=null && posvenda.dataHoraPlantasInstalacao ==null ">
                                                        <b-switch v-model="posvenda.devolverPlantasImpressao" @input="confirmarDevolucaoPlantasImpressaoVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVERPLANTASIMPRESSAO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                
                                                </article>       

                                                <article v-if="posvenda && posvenda.status==0 && habilitarAlteracaoGuia() && emVistoria"  class="panel">

                                                    <b-field  v-if="posvenda.dataHoraPlantasInstalacao !=null && posvenda.dataHoraVistoria ==null && posvenda.dataHoraPrimeiroContatoVistoria ==null ">
                                                        <b-switch :disabled="posvenda.concluirVistoria"  v-model="posvenda.primeiraVistoria" @input="confirmarPrimeiroContatoVistoriaPosVenda()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.PRIMEIRAVISTORIA')}}
                                                        </b-switch>                                            
                                                    </b-field>  

                                                    <b-field  v-if="posvenda.dataHoraPlantasInstalacao !=null && posvenda.dataHoraVistoria ==null ">
                                                        <b-switch :disabled="posvenda.concluirVistoria"  v-model="posvenda.retornoVistoria" @input="confirmarAbrirCalendario(2,null,true,'posvenda')"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.RETORNOVISTORIA')}}
                                                        </b-switch>                                            
                                                    </b-field>       

                                                    <b-field  v-if="posvenda.dataHoraPlantasInstalacao !=null && posvenda.dataHoraVistoria ==null && posvenda.status ==0 ">
                                                        <b-switch :disabled="posvenda.concluirVistoria" v-model="posvenda.vistoriaParcial" @input="confirmarVistoriaParcial()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.CONCLUIDOVISTORIAPARCIAL')}}
                                                        </b-switch>                                            
                                                    </b-field>     
                                                    
                                                    <b-field  v-if="posvenda.dataHoraPlantasInstalacao !=null && posvenda.dataHoraVistoria ==null ">
                                                        <b-switch v-model="posvenda.concluirVistoria" @input="confirmarConclusaoVistoria()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.CONCLUIDOVISTORIATOTAL')}}
                                                        </b-switch>                                            
                                                    </b-field>  
                                                    
                                                    <b-field  v-if="posvenda.dataHoraPlantasInstalacao !=null && posvenda.dataHoraVistoria ==null ">
                                                        <b-switch :disabled="posvenda.concluirVistoria"  v-model="posvenda.obranaoconforme" @input="confirmarObraNaoConformePosVenda()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.OBRANAOCONFORME')}}
                                                        </b-switch>                                            
                                                    </b-field>  
                                                                                                        
                                                </article>

                                                <article v-if="posvenda && posvenda.dataHoraPlantasInstalacao !=null 
                                                && posvenda.dataHoraVistoria ==null && habilitarAlteracaoGuia() && emVistoria" class="panel">
                                                    <div class="panel-heading has-text-centered">
                                                        {{$t('ATENDIMENTO.ULTIMASVISTORIAS')}}
                                                    </div>   
                                                    <div v-for="(agendamento, index) in agendamentosConferencia" v-bind:key="index">
                                                        <a v-if="(agendamento.agendamentoAutomatico==false)" class="panel-block">
                                                            <span class="panel-icon block">                                            
                                                                <b-icon :style="tipoAgendamentoStyle(agendamento)"  size="is-small" icon="square-rounded"></b-icon>                                            
                                                            </span>                                        
                                                            <p> {{agendamento.tipoAgendamentoNome}} - {{ agendamento.dataHora }}</p>
                                                        </a>
                                                    </div>                                                                             
                                                    <div class="panel-block">                                    
                                                        <b-button expanded class="button is-info" @click="confirmarAbrirCalendario(2,null,null,'posvenda')" > {{$t('ATENDIMENTO.ABRIRCALENDARIO')}} </b-button>                                            
                                                    </div>
                                                </article>
                                                                                                  

                                                <article v-if="posvenda && posvenda.status==0  && habilitarAlteracaoGuia() && emInstalacaoFutura"  class="panel">                                                    
                                                    <b-field  v-if="posvenda.dataHoraVistoria ==null || posvenda.dataHoraInstalacaoFutura ==null">
                                                        <b-switch v-model="posvenda.finalizarInstalacaoFutura" @input="confirmarFinalizacaoInstalacaoFutura(posvenda)"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.FINALIZARINSTALACAOFUTURA')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                                                                                             
                                                    
                                                </article>                                                  
                                                                                       

                                                <article v-if="posvenda && (posvenda.status==0 || posvenda.status==2) && habilitarAlteracaoGuia() && !emVistoria && !emInstalacaoFutura"  class="panel">                                                    
                                                    <b-field  v-if="posvenda.dataHoraInstalacaoFutura !=null && posvenda.dataHoraEntrega ==null">
                                                        <b-switch v-model="posvenda.devolverInstalacaoFutura" @input="confirmarDevolucaoInstalacaoFutura()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.DEVOLVERINSTALACAOFUTURA')}}
                                                        </b-switch>                                            
                                                    </b-field> 

                                                    <b-field  v-if="posvenda.dataHoraInstalacaoFutura !=null && posvenda.dataHoraEntrega ==null">
                                                        <b-switch  v-model="posvenda.agendarEntrega" @input="confirmarAgendamentoEntrega('ENTREGA','posvenda')" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.AGENDARENTREGA')}}
                                                        </b-switch>                                            
                                                    </b-field> 

                                                    <b-field  v-if="posvenda.dataHoraInstalacaoFutura !=null && posvenda.dataHoraEntrega ==null">
                                                        <b-switch  v-model="posvenda.agendarInstalacao" @input="confirmarAgendamentoInstalacao('INSTALAÇÃO','posvenda')" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.AGENDARINSTALACAO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     

                                                </article>                                                  
                                                                                       
                                                <article v-if="posvenda && posvenda.status==0 && habilitarAlteracaoGuia() 
                                                && posvenda.dataHoraInstalacaoFutura !=null  && posvenda.dataHoraEntrega ==null && !emVistoria && !emInstalacaoFutura" class="panel">
                                                    <div class="panel-heading has-text-centered">
                                                        {{$t('ATENDIMENTO.AGENDAMENTOENTREGA')}}
                                                    </div>                                                            
                                                    <div v-for="(agendamento, index) in agendamentosConferencia" v-bind:key="index">
                                                        <a v-if="(agendamento.agendamentoAutomatico==false)" class="panel-block">
                                                            <span class="panel-icon block">                                            
                                                                <b-icon :style="tipoAgendamentoStyle(agendamento)"  size="is-small" icon="square-rounded"></b-icon>                                            
                                                            </span>                                        
                                                            <p> {{agendamento.tipoAgendamentoNome}} - {{ agendamento.dataHora }}</p>
                                                        </a>
                                                    </div>                                                                             
                                                    <div class="panel-block">                                    
                                                        <b-button  expanded class="button is-info" @click="confirmarAbrirCalendario(3,null,null,'posvenda')" > {{$t('ATENDIMENTO.ABRIRCALENDARIO')}} </b-button>                                            
                                                    </div>
                                                </article>     
                                                
                                                
                                                <article v-if="posvenda && posvenda.tipo == 2 && posvenda.posVendaVinculadaId>0  && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field  v-if="emInstalacao &&  posvenda.dataHoraInstalacaoPausada == null">
                                                        <b-switch v-model="posvenda.fecharAssistencia" @input="confirmarFecharAssistencia(posvenda)"  type="is-success">
                                                            {{$t('MODALCADASTROASSISTENCIA.FECHARASSISTENCIA')}}
                                                        </b-switch>                                           
                                                    </b-field>  
                                                </article>                                                  
                                                
                                                <article v-else-if="posvenda && posvenda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field  v-if="emInstalacao &&  posvenda.dataHoraInstalacaoPausada == null">
                                                        <b-switch v-model="posvenda.finalizarInstalacao" @input="confirmarFinalizacaoInstalacao()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.FINALIZARINSTALACAO')}}
                                                        </b-switch>                                            
                                                    </b-field>
                                                    <b-field  v-if="emInstalacao && posvenda.tipo == 1 &&  posvenda.dataHoraInstalacaoPausada == null">
                                                        <b-switch v-model="posvenda.finalizarInstalacaoSemCertificado" @input="confirmarFinalizacaoSemCertificadoInstalacao()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.FINALIZARINSTALACAOSEMCERTIFICADO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                        
                                                    <b-field  v-if="emInstalacao &&  posvenda.dataHoraInstalacaoPausada == null">
                                                        <b-switch  v-model="posvenda.prazoInstalacao" @input="confirmarPrazoInstalacaoPosVenda()" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.INSTALACAOFUTURADATAPREVISTA')}}
                                                        </b-switch>                                                                                                  
                                                    </b-field>  
                                                    <b-field  v-if="emInstalacao &&  posvenda.dataHoraInstalacaoPausada == null">
                                                        <b-switch  v-model="posvenda.pausarInstalacao" @input="confirmarPausaInstalacaoPosVenda(false)" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.INSTALACAOPAUSADA')}}
                                                        </b-switch>                                                                                                    
                                                    </b-field> 
                                                    <b-field  v-if="posvenda.dataHoraInstalacaoPausada != null">
                                                        <b-switch  v-model="posvenda.pausarInstalacao" @input="confirmarPausaInstalacaoPosVenda(true)" type="is-success">
                                                            {{$t('GUIAATENDIMENTO.INSTALACAOLIBERADA')}}
                                                        </b-switch>                                                                                                    
                                                    </b-field>                                                                                                                                                              
                                                </article>       
                                                                                                    
                                                <article v-if="posvenda && posvenda.status==0 && habilitarAlteracaoGuia()"  class="panel">
                                                    <b-field  v-if="posvenda.dataHoraEmInstalacao !=null && posvenda.dataHoraInstalacaoIncompleta ==null">
                                                        <b-switch v-model="posvenda.terminarPosvenda" @input="confirmarterminarPosvenda()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.FINALIZARPOSVENDA')}}
                                                        </b-switch>                                            
                                                    </b-field>    
                                                    <b-field  v-if="posvenda.dataHoraEmInstalacao !=null && posvenda.dataHoraInstalacaoIncompleta ==null && !posvenda.certificado">
                                                        <b-switch v-model="posvenda.anexarCertificado" @input="confirmarAnexoCertificadoPosVenda()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.ANEXARCERTIFICADO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     
                                                </article>     
                                                                                                                                                                                                                                                              
                                                                                                                                                
                                                <b-notification v-if="paraInstalacao && agendamentosConferencia.length>0" type="is-info" has-icon icon-size="is-medium" :closable="false" role="alert" >
                                                    {{$t('GUIAATENDIMENTO.AGENDAMENTOPARAINSTALACAOREALIZADO').toUpperCase()}}                                  
                                                </b-notification>
                    
                                                <article v-if="posvenda && posvenda.dataHoraEntrega !=null && posvenda.dataHoraEmInstalacao ==null && habilitarAlteracaoGuia()" class="panel">
                                                    <div class="panel-heading has-text-centered">
                                                        {{$t('ATENDIMENTO.AGENDAMENTOINSTALACAO')}}
                                                    </div>   
                                                    <b-field  v-if="this.paraInstalacao">
                                                        <b-switch v-model="posvenda.instalacaoSemAgendamento" @input="confirmarInstalacaoSemAgendamento()"  type="is-success">
                                                            {{$t('GUIAATENDIMENTO.INSTALACAOEMANDAMENTO')}}
                                                        </b-switch>                                            
                                                    </b-field>                                                     
                                                    <div v-for="(agendamento, index) in agendamentosConferencia" v-bind:key="index">
                                                        <a v-if="(agendamento.agendamentoAutomatico==false)" class="panel-block">
                                                            <span class="panel-icon block">                                            
                                                                <b-icon :style="tipoAgendamentoStyle(agendamento)"  size="is-small" icon="square-rounded"></b-icon>                                            
                                                            </span>                                        
                                                            <p> {{agendamento.tipoAgendamentoNome}} - {{ agendamento.dataHora }}</p>
                                                        </a>
                                                    </div>                                                                             
                                                    <div  class="panel-block">                                    
                                                        <b-button expanded class="button is-info" @click="confirmarAbrirCalendario(3,'INSTALAÇÃO',null,'posvenda')" > {{$t('ATENDIMENTO.ABRIRCALENDARIO')}} </b-button>                                            
                                                    </div>
                                                </article> 


                                                <div v-if="posvenda" class="columns"   >
                                                    <div class="column is-12">  

                                                        <b-dropdown expanded rounded aria-role="list" >
                                                            <button class="button is-fullwidth" slot="trigger" slot-scope="{ active }">
                                                                <span>{{$t('GUIAATENDIMENTO.DOCUMENTOS')}}</span>
                                                                <b-icon :icon="active ? 'menu-up' : 'menu-down'"></b-icon>
                                                            </button>

                                                            <b-dropdown-item v-if="posvenda.dataHoraEntrega !=null && posvenda.dataHoraEmInstalacao ==null"   aria-role="listitem" >
                                                                <span class="columns">
                                                                    <a class="column is-12" @click="confirmarEntregas()" style="color: #4a4a4a; text-decoration: none;" >                                                            
                                                                        {{$t('GUIAATENDIMENTO.LIBERACAOENTREGA')}}                                                            
                                                                    </a>
                                                                </span>
                                                            </b-dropdown-item>
                                                            <b-dropdown-item v-if="posvenda.dataHoraEntrega !=null && !paraInstalacao"   aria-role="listitem" >
                                                                <span class="columns">
                                                                    <a class="column is-12" @click="acompanhamentoAssistencias()" style="color: #4a4a4a; text-decoration: none;" >                                                            
                                                                        {{$t('GUIAATENDIMENTO.ASSISTENCIASPENDENCIA')}}                                                            
                                                                    </a>
                                                                </span>
                                                            </b-dropdown-item>                                                            

                                                                                         
                                                        </b-dropdown>

                                                    </div>
                                                </div>   
                                        

                                                
                                                
                                                
                                                                                  
                                            </div>
                                        </div>                                                                                                                 
                                    </div>
                                   
                                              
                                </div>
                            </div>   
                            <div class="columns">    
                                <div class="column is-12">

          
                                    <div  v-if="posvenda && posvenda.dataHoraEntrega !=null && !paraInstalacao">
                                        <div class="columns">
                                            <div class="column is-6">      
                                                <b-button type="is-success" icon-left="cog"  @click="incluirAssistencia()"> 
                                                    {{$t('GUIAATENDIMENTO.NOVAASSISTENCIA')}} </b-button>                                                                                        
                                            </div>
                                            <div class="column is-6">      

                                            </div>

                                        </div>                                        
                                        <table class="table is-fullwidth">
                                            <thead>
                                                <tr>                                                    
                                                    <th class="is-size-7">Título</th>
                                                    <th class="is-size-7">Descrição</th>                                                    
                                                    <th class="is-size-7">Agendado?
                                                        <a href="#" class="has-text-info is-size-7" @click="alterarAgendamentoTodos()" :title="$t('GUIAATENDIMENTO.AGENDAMENTOMENSAGEMTODOS')">
                                                            <b-icon  icon="check-all" size="is-small"></b-icon>
                                                        </a>   
                                                    </th>                                                    
                                                    <th class="is-size-7">Elists</th>
                                                    <th class="is-size-7">Prazo</th>                                                    
                                                    <th class="is-size-7">Faturado?</th>                                                    
                                                    <th ></th>
                                                </tr>                                                
                                            </thead>
                                            <tbody> 
                                                <tr  :class="item.atraso ? 'bg-atraso' : '' " v-for="(item, index) in listaAssistencias" :key="index">                                                    
                                                    <td class="is-size-7">  
                                                        <span>                                                                                                            
                                                            <b>CUSTO DE: </b> {{item.custo}} 
                                                        </span> 
                                                        <br>                  
                                                        <span>                                                
                                                            <b>CONFERENTE: </b> {{item.funcionarioConferenteNome}}                                                             
                                                        </span>                                                                                                               
                                                        <br>     
                                                        {{item.titulo}}   
                                                         
                                                                                                                                                                                    
                                                    </td>
                                                    <td class="is-size-7"><span v-html="tratamentoQuebraLinha(item.observacao)"></span> </td>                                                    
                                                    <td class="is-size-7">
                                                        <span v-if="item.dataHoraAgendamento !=null">
                                                                {{item.dataHoraAgendamento}} 
                                                                    <a href="#" class="has-text-grey" @click="alterarAgendamento(item)" :title="$t('GUIAATENDIMENTO.AGENDAMENTOMENSAGEM')">
                                                                        <b-icon  icon="pencil" size="is-small"></b-icon>
                                                                    </a>                                                            
                                                            </span> 
                                                            <span v-else>a definir 
                                                                    <a href="#" class="has-text-grey" @click="alterarAgendamento(item)" :title="$t('GUIAATENDIMENTO.AGENDAMENTOMENSAGEM')">
                                                                        <b-icon  icon="pencil" size="is-small"></b-icon>
                                                                    </a>                                
                                                            </span>                                                          
                                                    </td>                                                    
                                                    <td class="is-size-7">{{item.eList}} </td>                                                    
                                                    <td class="is-size-6" >

                                                        <span v-if="item.posics &&item.posics.length>0 && item.posics[0]!=null " style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                            <b>PRAZO FÁBRICA 1: </b> 
                                                            <span v-if="item.posics[0].dataHoraPrevisao !=null">
                                                                {{$moment(item.posics[0].dataHoraPrevisao).format("DD/MM/YYYY") }}                                                       
                                                            </span>                                                                                                                                                             
                                                        </span>  
                                                        <span v-if="item.posics &&  item.posics.length>0 &&item.posics[1]!=null "  style="margin-top: -5px !important;display: block; font-size: 0.7em;">
                                                            <b>PRAZO FÁBRICA 2: </b> 
                                                            <span v-if="item.posics[1].dataHoraPrevisao !=null">
                                                                {{$moment(item.posics[1].dataHoraPrevisao).format("DD/MM/YYYY") }}                                                       
                                                            </span>                              
                                                                                                        
                                                        </span>   
                                                        <span>
                                                            <span>                                                              
                                                                    <a href="#" class="has-text-info is-size-7" @click="visualizarPosicsAssistencia(item)" :title="$t('GUIALIST.VISUALIZARPOSICS')">
                                                                        <b-icon  icon="eye" size="is-small"></b-icon> Ver Todos
                                                                    </a>                                 
                                                            </span>
                                                        </span>                                                                            
                                                                                                                                                                                                                                                   
                                                    </td>
                                                    <td class="is-size-7">
                                                        <b-switch size="is-small" :disabled="item.faturado" v-model="item.faturado" @input="confirmarFaturamento(item)"   ></b-switch>                                                        
                                                        <span v-if="item.dataHoraLiberacaoEntrega">                                                                                                            
                                                            <br>
                                                            <b>Lib. Entrega: </b>  {{item.dataHoraLiberacaoEntrega}}  
                                                            <a href="#" class="has-text-info is-size-7" @click="confirmarEntregas(item.codigoLote)" :title="$t('GUIALIST.DOCUMENTOENTREGA')">   
                                                                <b-icon  icon="file" size="is-small"></b-icon> 
                                                            </a>                                                                               
                                                             
                                                        </span> 
                                                    </td>
                                                    <td>                     
                                                        <div style="display: flex;">

                                                            <span v-if="item.dataHoraCancelado !=null">
                                                                <b-icon icon="close-circle"
                                                                                size="is-small"                                    
                                                                                :title="$t('GUIAATENDIMENTO.ASSISTENCIAEXCLUIDA')"
                                                                                type="is-danger"></b-icon> 
                                                            </span>                                                        
                                                            <span v-else-if="item.dataHoraConcluido !=null">
                                                                <b-icon icon="check"
                                                                                size="is-small"                                    
                                                                                :title="$t('GUIAATENDIMENTO.ASSISTENCIACONCLUIDA')"
                                                                                type="is-success"></b-icon> 
                                                            </span>
                                                            <span v-else>
                                                                <b-icon icon="information"
                                                                                size="is-small"                                    
                                                                                :title="$t('GUIAATENDIMENTO.ASSISTENCIANAOCONCLUIDA')"
                                                                                type="is-warning"></b-icon>                                                             
                                                            </span>                                                                                                                          
                                                            <b-button v-if="item.dataHoraCancelado ==null" :title="$t('SISTEMA.EDITAR')" class="ml-1  is-small is-primary" @click="incluirAssistencia(item.id)" icon-left="pencil"></b-button>   
                                                            <b-button v-if="isInRole('posvenda-assistencia-excluir') && item.dataHoraConcluido ==null && item.dataHoraCancelado ==null" :title="$t('SISTEMA.REMOVER')" class="ml-1 is-small is-danger" @click="excluirAssistencia(item.id)" icon-left="delete"></b-button>                                                       

                                                        </div>                                   

                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>                         
                                                                                                                                                   
                                </div>
                        
                            </div>                                                  
                    </b-tab-item>                    
                </b-tabs>

                <div class="columns ">
                    <div class="column is-12">      
                        <b-tabs @input="mudouObsTab" v-model="tabs" multiline>
                            <b-tab-item value="observacoes" :label="$t('SISTEMA.OBSERVACAO')" icon="comment">
                                <div class="columns">
                                    <div class="column is-10">      
                                        <textarea class="textarea" v-model="guia.observacoes" rows="1"></textarea>
                                    </div>
                                                                                                                      
                                </div>

                                <article v-if="guia.id > 0" class="panel mb-0">
                                    <div class="panel-heading ">
                                        {{$t('ATENDIMENTO.ULTIMASINFORMACOES')}}
                                        <span class="is-pulled-right" >
                                            <b-select size="is-small" class="ml-5" v-model="filtroObservacoes" @input="loadHistoricoAlteracoes()">
                                                    <option value="Todos">Todos</option>
                                                    <option value="Usuario">Usuário</option>
                                                    <option value="Sistema">Sistema</option>
                                                </b-select>                                             
                                        </span>
                                    </div>                                                            
                                    <table v-if="historicoAlteracoes !=null" class="table is-fullwidth">
                                        <tr v-for="(item, index) in historicoAlteracoes.slice(0,3)" :key="index">
                                            <td>
                                                <h4>
                                                    {{ item.observacao }}   
                                                    <span v-if="item.chaveComplemento !=null"> 
                                                        <router-link :to="{ name: 'guia', params: { id:item.chaveComplemento, guiaList:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                                    </router-link>  
                                                    </span>                                  
                                                </h4>
                                            </td>
                                            <td>
                                                <span><b>{{ item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                            </td>
                                            <td>
                                                <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                            </td>
                                        </tr>
                                    </table>                                                                               
                                </article>
                                <b-collapse class="panel m-0" animation="slide" :open="false" >
                                    <template #trigger="props">
                                        <div
                                            class="card-header" role="button" :aria-expanded="props.open">
                                            <p class="card-header-title">
                                                {{$t('ATENDIMENTO.TODASINFORMACOES')}}
                                               
                                            </p>
                                            <a class="card-header-icon">
                                                <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
                                            </a>
                                        </div>
                                    </template>
                                    <table v-if="historicoAlteracoes !=null"  class="table is-fullwidth">
                                        <tr v-for="(item, index) in historicoAlteracoes" :key="index">
                                            <td>
                                                <h4>
                                                    {{ item.observacao }}   
                                                    <span v-if="item.chaveComplemento !=null"> 
                                                        <router-link :to="{ name: 'guia', params: { id:item.chaveComplemento, guiaList:true}}" class="button is-small is-primary" title="Abrir Atendimento">
                                                            <b-icon icon="pencil" size="is-small"></b-icon>
                                                    </router-link>  
                                                    </span>                                  
                                                </h4>
                                            </td>
                                            <td>
                                                <span><b>{{ item.usuarioNome }}</b> ({{ item.usuarioId }})</span>
                                            </td>
                                            <td>
                                                <span>{{ item.dataHora | moment('DD/MM/YYYY HH:mm') }}</span>
                                            </td>
                                        </tr>
                                    </table>                                        
                                </b-collapse>

                                
                            </b-tab-item>

                            <b-tab-item v-if="guia.id != 0" value="anexos" :label="$t('GUIAATENDIMENTO.ANEXOS')" icon="paperclip">
                                <div class="columns">
                                    <div class="column is-4" >
                                        <b-field  v-if="visualizarAnexos()">
                                            <b-upload 
                                                    @input="confirmarAnexo"
                                                    v-model="fileList"                                                     
                                                    accept=".pdf,.jpg,.jpeg,.png,.mp4,.mov,.wmv,.PNG,.PDF,.JPG,.JPEG,.MP4,.MOV,.WMV"                                                    
                                                    drag-drop>
                                                <section class="section">
                                                    <div class="content has-text-centered">
                                                        <p>
                                                            <b-icon icon="upload"
                                                                    size="is-medium">
                                                            </b-icon>
                                                        </p>
                                                        <p>{{$t('ANEXOS.ARRASTEARQUIVOS')}}</p>
                                                        <p>({{$t('ANEXOS.APENASPDF')}})</p>
                                                    </div>
                                                </section>
                                            </b-upload>
                                        </b-field>
                                        <a v-if="visualizarAnexos()" href="#" class="has-text-info" @click="adicionarUrlExterna()" :title="$t('GUIAATENDIMENTO.URLEXTERNA')">
                                            <b-icon  icon="link" size="is-small"></b-icon>
                                        </a>                                         
                                    </div>
                                    <div class="column is-8">
                                        <table v-if="anexos!=null && anexos.length>0" class="table is-striped">
                                            <thead>
                                                <tr>
                                                    <th>Tipo</th>                        
                                                    <th>Nome</th>
                                                    <th>Data</th>
                                                    <th>Ação</th>
                                                </tr>                                                
                                            </thead>
                                            <tbody>
                                                
                                                <tr :class="anexo.documentoDigital ? 'has-background-info-light' : '' " v-for="(anexo, index) in anexos" :key="index">
                                                    <td> {{retornaTipoAnexo(anexo)}} </td>
                                                    <td >                                        
                                                        <p>
                                                            <a v-if="!anexo.documentoDigital && anexo.formato==0" @click="visualizarAnexo(anexo.id, anexo.fileName)" class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>
                                                            <a v-else-if="!anexo.documentoDigital && anexo.formato==1" @click="visualizarUrlExternaAnexo(anexo.anexo)" class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>
                                                            <a v-else @click="visualizarAnexoDigital(anexo.id)" class="is-size-7 text-nowrap"><b>{{ anexo.fileName }}</b></a>
                                                        </p>
                                                    </td>

                                                    <td>{{ anexo.dataHora ? $moment(anexo.dataHora).format("DD/MM/YYYY") : ''}}</td>
                                                    
                                                    <td v-if="anexo.id ">        
                                                        <div class="is-inline-flex">
                                                            <b-button v-if="isInRole('atendimento-anexoeditar') && !anexo.documentoDigital"
                                                                    icon-left="pencil"
                                                                    type="is-primary"
                                                                    size="is-small"
                                                                    :title="$t('SISTEMA.EDITAR')"
                                                                    @click="editarAnexo(anexo)"></b-button>
                                                             
                                                            <span v-if="isInRole('atendimento-anexoexcluir') ">
                                                                <b-button v-if="!anexo.documentoDigital"
                                                                    class="ml-2"
                                                                    icon-left="delete"
                                                                    type="is-danger"
                                                                    size="is-small"
                                                                    :title="$t('ANEXOS.REMOVERANEXO')"
                                                                    @click="removerAnexo(anexo.id)"></b-button> 
                                                                <b-button v-else
                                                                    class="ml-2"
                                                                    icon-left="delete"
                                                                    type="is-danger"
                                                                    size="is-small"
                                                                    :title="$t('ANEXOS.REMOVERANEXO')"
                                                                    @click="removerAnexoDigital(anexo.id)"></b-button>                                                                                                                                    
                                                            </span>
                                                            

                                                        </div>                                   
                                                    </td>
                                                </tr>

                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                            </b-tab-item>  

                            <b-tab-item v-if="this.guia.id != 0" value="ambientes" :label="$t('GUIAATENDIMENTO.AMBIENTES')" icon="home-group">

                                <div class="columns">
                                    <div class="column is-12"> 
                                        <b-button  type="button" class="is-info is-pulled-right ml-1" @click="atualizarListarAmbientes()" icon-left="home-edit" title="Selecionar e atualizar ambientes do EList"></b-button>

                                        <b-button  v-if="isInRole('integracao-ambientes-elist')" type="button" class="is-info is-pulled-right" @click="atualizarAmbientes()" icon-left="home-export-outline" title="Atualizar ambientes do EList"></b-button>
                                        
                                    </div>           
                                </div>                     
                                <div class="columns">

                                    <div class="column is-12">
                                        <table v-if="ambientes !=null && ambientes.length>0" class="table  is-fullwidth is-striped">
                                            <thead>
                                                <tr>
                                                    <th>Opção</th>
                                                    <th>Ambiente</th> 
                                                    <th>Tipo</th>
                                                    <th>Situação</th>                                                                                                       
                                                    <th>Nr. Elist</th>
                                                    <th>Valor</th>
                                                    <th></th>
                                                </tr>                                                
                                            </thead>
                                            <tbody>                                                
                                                <tr v-for="(ambiente, index) in ambientes" :key="index">
                                                    
                                                    <td>{{ ambiente.opcao  }}</td>
                                                    <td>{{ ambiente.descricao }}</td>                                                    
                                                    <td>{{ ambiente.tipoDescricao }}</td>                            
                                                    <td>{{ ambiente.tipoSituacao }}</td>                                                                                                            
                                                    <td>{{ ambiente.elist }}</td>
                                                    <td>R$ {{ ambiente.valor.toString().replace('.',',') }}</td>                                                     
                                                    <td>
                                                        <b-button v-if="isInRole('ambientes-elist-excluir') " :title="$t('SISTEMA.REMOVER')" class="ml-1 is-small is-danger" @click="excluirAmbiente(ambiente.id)" icon-left="delete"></b-button>                                                                                                                                                                                                                                                                                                                                                                 
                                                    </td>                                                    
                        
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div> 
                            </b-tab-item>                             

                            <b-tab-item v-if="this.guia.id != 0 && false" value="venda" :label="$t('GUIAATENDIMENTO.VENDA')" icon="cash">                            
                            </b-tab-item>                                              
                        </b-tabs> 
                    </div>
                </div>                               
            </div>
            <nav class="level">
                <div class="level-item">
                    <p class="buttons">
                        <b-button v-if="habilitarAlteracaoGuia()" type="is-success"
                                  :loading="salvandoDados"
                                   @click="salvarGuia"
                                  icon-left="check-circle">
                            {{$t('SISTEMA.SALVAR')}}
                        </b-button>
                        <b-button type="is-danger"
                                  native-type="button"
                                  icon-left="close-circle"
                                  @click="$router.back();">
                            {{$t('SISTEMA.CANCELAR')}}
                        </b-button>

 
                        
                    </p>
                </div>
            </nav>
        </ValidationObserver>

    </section>
</template>

<style scoped>
    .sadtExame {
        margin-right: 2px;
        cursor: pointer;
    }
</style>

<script>
    import { mapGetters, mapState } from 'vuex'
    import titlebar from '@/components/titlebar.vue'
    import searchIdName from '@/components/searchIdName.vue'        
    import debounce from 'lodash/debounce'        
    import { length } from 'vee-validate/dist/rules'
    
    import modalCancelandoGuia from '@/components/atendimento/modalCancelandoGuia'
    import modalTrocarVendedorGuia from '@/components/atendimento/modalTrocarVendedorGuia.vue'
    import modalEmEsperaGuia from '@/components/atendimento/modalEmEsperaGuia'
    import modalDevolucaoProjetistaGuia from '@/components/atendimento/modalDevolucaoProjetistaGuia'
    import modalDevolucaoEfetivacaoVenda from '@/components/venda/modalDevolucaoEfetivacaoVenda'
    import modalDevolucaoFinanceiroVenda from '@/components/venda/modalDevolucaoFinanceiroVenda'
    import modalDevolucaoAssinaturaVenda from '@/components/venda/modalDevolucaoAssinaturaVenda'
    import modalTransformaEntregaFuturaPlantasVenda from '@/components/venda/modalTransformaEntregaFuturaPlantasVenda'
    import modalTransformaEntregaFuturaVenda from '@/components/venda/modalTransformaEntregaFuturaVenda'
    import modalDevolucaoInstalacaoFuturaPosVenda from '@/components/posvenda/modalDevolucaoInstalacaoFuturaPosVenda'    
    import modalDevolucaoEletrosVenda from '@/components/venda/modalDevolucaoEletrosVenda'
    import modalDevolucaoGerenteConferenciaVenda from '@/components/venda/modalDevolucaoGerenteConferenciaVenda'
    import modalDevolucaoMedidorVenda from '@/components/venda/modalDevolucaoMedidorVenda'
    import modalDevolucaoPlantasVenda from '@/components/posvenda/modalDevolucaoPlantasVenda'
    import modalDevolucaoPlantasImpressaoVenda from '@/components/posvenda/modalDevolucaoPlantasImpressaoVenda'    
    import modalRemoverEntregaFuturaVenda from '@/components/venda/modalRemoverEntregaFuturaVenda'
    import modalConcluirVistoriaMedicao from '@/components/venda/modalConcluirVistoriaMedicao'
    import modalSolicitarVistoriaMedicao from '@/components/venda/modalSolicitarVistoriaMedicao'    
    import modalConcluidoPlantasEntregaFuturaVenda from '@/components/venda/modalConcluidoPlantasEntregaFuturaVenda'        
    import modalDevolverConferenteVistoriaMedicao from '@/components/venda/modalDevolverConferenteVistoriaMedicao'    
    import modalRemoverEntregaFuturaPlantasVenda from '@/components/venda/modalRemoverEntregaFuturaPlantasVenda'
    import modalPrazoEntregaEletroVenda from '@/components/venda/modalPrazoEntregaEletroVenda'    
    import modalEditarAnexo from '@/components/atendimento/modalEditarAnexo'    
    import modalPreencherTipoAnexo from '@/components/atendimento/modalPreencherTipoAnexo'        
    import modalConferenciaConcluidaParcialVenda from '@/components/venda/modalConferenciaConcluidaParcialVenda'  
    import modalCadastroAssistenciaPosVenda from '@/components/posvenda/modalCadastroAssistenciaPosVenda'    
    import modalFinalizarInstalacaoPosVenda from '@/components/posvenda/modalFinalizarInstalacaoPosVenda' 
    import modalConferenciaConcluidaVenda from '@/components/venda/modalConferenciaConcluidaVenda' 
    import modalConferenciaConcluidaTipoAssistenciaVenda from '@/components/venda/modalConferenciaConcluidaTipoAssistenciaVenda'     
    import modalConcluirAssinaturaVenda from '@/components/venda/modalConcluirAssinaturaVenda.vue' 
    import modalAnexarProjetoVenda from '@/components/venda/modalAnexarProjetoVenda.vue'     
    import modalConcluirAssinaturaDigitalVenda from '@/components/venda/modalConcluirAssinaturaDigitalVenda.vue' 
    import modalConcluirAssinaturaGerenteVenda from '@/components/venda/modalConcluirAssinaturaGerenteVenda.vue' 
    import modalConcluirEletrosAnexo from '@/components/venda/modalConcluirEletrosAnexo.vue' 
    import modalLiberarEntregaPosVenda from '@/components/posvenda/modalLiberarEntregaPosVenda'
    import modalPrimeiroContatoVistoriaPosVenda from '@/components/posvenda/modalPrimeiroContatoVistoriaPosVenda'  
    import modalVistoriaParcialPosVenda from '@/components/posvenda/modalVistoriaParcialPosVenda'    
    import modalEntregaParcialPosVenda from '@/components/posvenda/modalEntregaParcialPosVenda'  
    import modalPrazoInstalacaoPosVenda from '@/components/posvenda/modalPrazoInstalacaoPosVenda'    
    import modalInstalacaoPausadaPosVenda from '@/components/posvenda/modalInstalacaoPausadaPosVenda.vue'    
    import modalAnexarCertificadoPosVenda from '@/components/posvenda/modalAnexarCertificadoPosVenda'
    import modalListarPosicsAssistencia from '@/components/posvenda/modalListarPosicsAssistencia'
    import modalListarAssistencias from '@/components/posvenda/modalListarAssistencias.vue'
    import modalObraNaoConfirmidadePosVenda from '@/components/posvenda/modalObraNaoConfirmidadePosVenda.vue'  
    import modalObraNaoConformeVendedorPosVenda from '@/components/posvenda/modalObraNaoConformeVendedorPosVenda.vue'  
    
    

    import modalPrazoAssinaturaVenda from '@/components/venda/modalPrazoAssinaturaVenda'
    import modalPrazoEntregaFuturaVenda from '@/components/venda/modalPrazoEntregaFuturaVenda'
    import modalMedicaoParcialVenda from '@/components/venda/modalMedicaoParcialVenda'
    import modalNotificaGuia from '@/components/atendimento/modalNotificaGuia'
    import modalListarAmbientes from '@/components/atendimento/modalListarAmbientes.vue'
    import modalObservacaoGuia  from '@/components/atendimento/modalObservacaoGuia'
    import modalUrlExternaAnexo  from '@/components/atendimento/modalUrlExternaAnexo'
    import modalReativarGuia  from '@/components/atendimento/modalReativarGuia.vue'
    import modalAlteracaoProjeto  from '@/components/atendimento/modalAlteracaoProjeto.vue'
    import modalListarArquitetos from '@/components/atendimento/modalListarArquitetos.vue' 
    import modalDocumentoVenda from '@/components/venda/modalDocumentoVenda'    
    import modalDocumentoCheckListVenda from '@/components/venda/modalDocumentoCheckListVenda'  
    import moment from 'moment'
    

    export default {
        data() {
            return {                
                alterandoUnidade: false,                
                guia: {
                    id: null,   
                    codigoExterno:null,                                                         
                    unidadeDeCadastroId: null, 
                    autorizacao:false,  
                    prazoProjetoEmDias:0, 
                    prazoVendedorEmDias: 0,
                    numeroAmbiente: 0                
                },
                venda:null,
                posvenda:null,
                salvandoDados: false,                
                anexos: null, 
                tabs: 'observacoes', 
                tabsPrincipal: 'atendimento',
                isLoading: false,  
                historicoAlteracoes: null,                
                agendamentos:[],
                agendamentosMedidor:[],
                agendamentosConferencia:[],                 
                listaAssistencias:[],               
                negociacao:null, 
                campoDisableTemperatura: false,                                                                                                                        
                campoDisabledPrazoVendedor: false,
                emVistoriaMedicao: this.$route.params.emVistoriaMedicao ?? false,  
                fluxoVenda: this.$route.params.fluxoVenda ?? false,                                                                                                                              
                fluxoPosVenda: this.$route.params.fluxoPosVenda ?? false,       
                guiaList: this.$route.params.guiaList ?? false,
                paraInstalacao: this.$route.params.paraInstalacao ?? false,   
                emInstalacao: this.$route.params.emInstalacao ?? false,  
                emInstalacaoFutura: this.$route.params.emInstalacaoFutura ?? false,  
                emVistoria: this.$route.params.emVistoria ?? false,  
                fileList:null,
                ambientes:[], 
                filtroObservacoes: 'Todos'
            }
        },
        components: {
            titlebar,            
            searchIdName                               
        },
        computed: {

            titleStack() {
                return [
                    this.$t('SISTEMA.ATENDIMENTO'),                    
                    this.guia.id >0 ? this.guia.id : this.$t('SISTEMA.NOVO')
                ]
            },
            ...mapState([
                'unidadeId',
                'config',                                             
                'usuario', 
            ]),
            ...mapGetters([
                'isInRole'
            ]),           
        },
        mounted() {                             
            this.loadGuia();
        },
        watch: {
            // call again the method if the route changes
            '$route': 'loadGuia'
        },
        methods: {

            retornaTipoAnexo(anexo){
              
              if(parseInt(anexo.formato) == 0){
                  return anexo.tipoAnexoNome ?? 'NAO DEFINIDO';
              }else{
                  return 'LINK EXTERNO'
              }                 

          },            

            preencherDadosArquiteto(arquiteto){

                    this.salvandoDados=true;
                    const params = [];                    
                    params.push(`id=${this.guia.id}`); 
                    params.push(`unidadeId=${this.unidadeId}`);               
                
                    this.$http.post(`/api/atendimento/AtualizarArquiteto?${params.join('&')}`,arquiteto)
                    .then((res) => {
                        this.salvandoDados=false;
                    }).finally(() => {                                
                        this.salvandoDados = false;                         
                        this.loadGuia();                                                                                 
                    });    
                
            },             
            atualizarArquitetoElist(){
                const params = [];                                                                                                                      
                params.push(`guiaId=${this.guia.id}`);  
                params.push(`unidadeId=${this.unidadeId}`);  
                params.push(`clienteId=${this.guia.clienteId}`); 

                this.$buefy.dialog.confirm({
                        title: 'Atualizar Arquiteto do Elist',
                        message: 'Deseja importar e atualizar o arquiteto do Elist?',
                        type: 'is-info',
                        hasIcon: true,
                        cancelText: 'Não',
                        confirmText: 'Sim',
                        onConfirm: () => {

                                //Se ja tiver efetivado/vendido. Usar metodo de indicadores para puxar arquiteto cadastrado final
                                if (this.guia.dataHoraVenda != null || this.guia.dataHoraEfetivacao !=null){
                                    
                                    try {              

                                        this.$http.get(`/api/integracao/AtualizarArquitetoEList?${params.join('&')}`)                        
                                        .then(res => res.data)
                                        .then(data => {                                                
                                            return true;
                                        }).finally(() => { 
                                            if(this.guia.id >0)  
                                                this.loadGuia();

                                            this.$buefy.toast.open({
                                                duration: 5000,
                                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                                type: 'is-success'
                                            });                                    
                                        });                
                                        
                                    } catch (e) {                                            
                                        console.error(e);
                                    }                                      

                                }else{// se nao tiver vendido entao usar metodo de cliente, para puxar arquiteto do cadastro do cliente

                                    this.$buefy.modal.open({
                                        parent: this,
                                        component: modalListarArquitetos,
                                        props: {
                                            guiaId: this.guia.id,                                      
                                            clienteId :this.guia.clienteId                                       
                                        },  
                                        events: {
                                            preencherDadosArquiteto: this.preencherDadosArquiteto                        
                                        },                                                                                                                         
                                        hasModalCard: true,
                                        trapFocus: true,
                                        fullScreen: false
                                    })

                                }
                                                                    
                               
                        }
                    });                
                                                                        

            },            
            visualizarPosicsAssistencia(assistencia){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarPosicsAssistencia,
                    props: {
                        assistencia: assistencia                      
                    },
                    events: {
                        loadData: this.loadGuia,                         
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                })


                return true;
            },                  
            atualizarPosics(){                
                const params = [];                                                                                                                      
                params.push(`guiaId=${this.posvenda.guiaId}`);  
                params.push(`unidadeId=${this.unidadeId}`);  
                                                                        
                try {                            
                    this.$http.get(`/api/integracao/AtualizarPrazoAmbienteEList?${params.join('&')}`)                        
                    .then(res => res.data)
                    .then(data => {                                                
                        return true;
                    });                            
                    
                } catch (e) {                                            
                    console.error(e);
                }   
            },
            adicionarUrlExterna(){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalUrlExternaAnexo,
                    props: {
                        guia: this.guia                                              
                    },
                    events: {
                        loadData: this.loadGuia
                    },                    
                    hasModalCard: true,
                    trapFocus: true
                })                
       
            },     
            visualizarUrlExternaAnexo(url){
                window.open(url);
            },                    
            visualizarAnexos(){
            
                if(this.guia.id != 0)
                    return true;                
            },
            atualizarListarAmbientes(){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarAmbientes,
                    props: {
                        guia: this.guia                      
                    },
                    events: {
                        loadData: this.loadGuia,                        
                    },                    
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })

            },
            atualizarAmbientes(){
                    this.$buefy.dialog.confirm({
                        title: 'Atualizar ambientes do Elist',
                        message: 'Deseja importar os ambientes do Elist deste atendimento?',
                        type: 'is-info',
                        hasIcon: true,
                        cancelText: 'Não',
                        confirmText: 'Sim',
                        onConfirm: () => {
                        
                            this.isLoading=true;
                            const params = [];                                                                                                      
                            params.push(`clienteId=${this.guia.clienteCodigoExterno}`);  
                            params.push(`guiaId=${this.guia.id}`);  
                            params.push(`unidadeId=${this.unidadeId}`);  
                                                                                    
                            try {                            
                                this.$http.get(`/api/integracao/AtualizarAmbientesEList?${params.join('&')}`)                        
                                .then(res => res.data)
                                .then(data => {     
                                    this.messages = data;                       
                                    this.isLoading=false;
                                }).finally(() => {                                
                                    this.isLoading = false; 
                                    if(this.messages !=null && this.messages.length>0){
                                        this.messages.forEach((m) => {

                                        this.$buefy.toast.open({
                                            duration: 10000,
                                            message: m,
                                            type: 'is-success',
                                            queue: false
                                        })                                    

                                        });                  
                                    }

                                    this.loadAmbientes();                                                                                                    
                                    this.loadGuia();
                                }).catch((error) => {    

                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: "Ocorreu um erro na integração com Elist!",
                                        type: 'is-danger',
                                        queue: false
                                    });                                                    
                                    throw error;

                                });                               
                                
                            } catch (e) {
                                this.salvandoDados=false;                        
                                console.error(e);
                            }
                        }
                    });

            },            
            confirmarFaturamento(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('ATENDIMENTO.MENSAGEMCONFIRMARFATURAMENTOASSISTENCIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim', 
                    type:"is-info",                      
                    hasIcon: true,                                         
                    onConfirm: () => {   

                        this.salvandoDados=true;
                        const params = [];                    
                        params.push(`id=${item.id}`);                                                                                    
                        params.push(`posvendaId=${this.posvenda.id}`);                         
                    
                        this.$http.get(`/api/assistencia/AssistenciaEntrega?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.salvandoDados=false;
                            this.$router.back();      
                        });                          
                            
                    },
                    onCancel: () => {   
                        item.faturado = false;                                                                     
                        this.salvandoDados = false;    
                    },
                })                  
                
                return true;
            },
            alterarPrazo(assistencia){                
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIAATENDIMENTO.PRAZOMENSAGEM'),                    
                    inputAttrs: {
                        type: 'date',                        
                        placeholder: 'Prazo',
                        value: assistencia.dataHoraPrazo
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.get(`/api/assistencia/SalvarPrazoAssistencia?prazo=${moment(res).format('YYYY-MM-DDTHH:mm:ss')}&id=${assistencia.id}`)
                        .then((r) => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                type: 'is-success'
                            });
                            assistencia.dataHoraPrazo = moment(res).format('DD/MM/YYYY');
                        }, error => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Não foi possível salvar a data",
                                type: 'is-danger'
                            });
                            assistencia.dataHoraPrazo= null;
                        }
                    )}
                });                
                return true;
            },      
            alterarAgendamentoTodos(){           
                this.$buefy.modal.open({
                    parent: this,
                    component: modalListarAssistencias,
                    props: {
                        posVendaId: this.posvenda.id                      
                    },
                    events: {
                        loadData: this.loadGuia,                        
                    },                    
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })


            },
            alterarAgendamento(assistencia){                
                this.$buefy.dialog.prompt({
                    title: this.$t('GUIAATENDIMENTO.AGENDAMENTOMENSAGEM'),                    
                    inputAttrs: {
                        type: 'date',                        
                        placeholder: 'Agendamento',
                        value: assistencia.dataHoraAgendamento
                    }, 
                    confirmText: 'Confirmar',
                    cancelText: 'Cancelar',
                    trapFocus: true,
                    onConfirm: (res) => {
                        this.$http.get(`/api/assistencia/DataHoraAgendamentoAssistencia?prazo=${moment(res).format('YYYY-MM-DDTHH:mm:ss')}&id=${assistencia.id}`)
                        .then((r) => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: this.$t("SISTEMA.SALVOSUCESSO"),
                                type: 'is-success'
                            });
                            assistencia.dataHoraAgendamento = moment(res).format('DD/MM/YYYY');
                        }, error => {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: "Não foi possível salvar a data",
                                type: 'is-danger'
                            });
                            assistencia.dataHoraAgendamento= null;
                        }                        
                        ).finally(() => {      
                            this.carregarAssistencias();                                                                
                        }); 
                        
                    }
                });                
                return true;
            },                           
            tratamentoQuebraLinha(texto){                
                return (texto) ? texto.replaceAll('\\n', '<br/>') : '';
            },             
            confirmarAgendamentoEntrega(filtrarTiposAgenda,voltarTela){

                                                    
                if(this.agendamentosConferencia.filter(x=>x.tipoAgendamentoNome=='ENTREGA').length==0){
                    this.confirmarAbrirCalendario(3,filtrarTiposAgenda,null,voltarTela);
                }else{
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMAGENDAMENTOENTREGA'),
                        type: 'is-info',
                        hasIcon: true
                    });
                    setTimeout(() => {this.posvenda.agendarEntrega = false;},1000);
                }

            },
            confirmarAgendamentoInstalacao(filtrarTiposAgenda,voltarTela){

                                                    
                if(this.agendamentosConferencia.filter(x=>x.tipoAgendamentoNome=='INSTALAÇÃO' || x.tipoAgendamentoNome=='INSTALACAO').length==0){
                    this.confirmarAbrirCalendario(3,filtrarTiposAgenda,null,voltarTela);
                }else{
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMAGENDAMENTOINSTALACAO'),
                        type: 'is-info',
                        hasIcon: true
                    });
                    setTimeout(() => {this.posvenda.agendarInstalacao = false;},1000);
                }

            },            
            confirmarAbrirCalendario(tipo,filtrarTiposAgenda,filtrarTodos=false,voltarTela=null){       
                                
                this.abrirCalendario(tipo,filtrarTiposAgenda,filtrarTodos,voltarTela);
            },            
            habilitarAlteracaoGuia(){
                return (this.guia.dataHoraCancelado ==null && !this.guiaList) || this.isInRole('Administrador') || this.fluxoVenda || this.fluxoPosVenda
            },
            atualizaStatusGuia(){
                if(this.negociacao=="venda"){
                    this.documentoDeVenda();
                }else if (this.negociacao=="alteracao"){
                    this.confirmarAlteracaoProjeto();
                }else if (this.negociacao=="desistencia"){
                    this.confirmarCancelamento(true);  
                }else if (this.negociacao=="emespera"){
                    this.confirmarEmEspera();
                }
            },
            confirmarNotificacao() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalNotificaGuia,
                    props: {
                        guia: this.guia                        
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },
            confirmarInclusaoObservacao() {
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObservacaoGuia,
                    props: {
                        guia: this.guia                                              
                    },
                    events: {
                        loadData: this.loadGuia
                    },                    
                    hasModalCard: true,
                    trapFocus: true
                })
            },            
            confirmarCancelamento(desistencia){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCancelandoGuia,
                    props: {
                        id: this.guia.id,
                        desistencia: desistencia
                    },
                    events: {
                        loadData: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },  

            confirmarTrocaVendedor(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalTrocarVendedorGuia,
                    props: {
                        guiaId: this.guia.id,
                        guiaFuncionarioComercialId : this.guia.funcionarioComercialId                        
                    },
                    events: {
                        loadData: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },              
               
            acompanhamentoAssistencias(){

                const url = `/api/posvenda/AcompanhamentoAssistenciaPDF?posvendaId=`+this.posvenda.id;
                window.open(url, "_blank");
                
            },   
            confirmarEntregas(codigoLoteAssistencia){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalLiberarEntregaPosVenda,
                    props: {
                        codigoLote: (codigoLoteAssistencia >0 ) ? codigoLoteAssistencia : this.posvenda.codigoLote,
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            },

            removerMedidorParcialVenda (){
                this.venda.medidorparcial = false;                
            },               

            confirmarMedidorParcialVenda(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalMedicaoParcialVenda,
                    props: {
                        id: this.venda.id,
                        guiaId: this.guia.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerMedidorParcialVenda: this.removerMedidorParcialVenda
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })

            },             
            

            removerVistoriaParcial(){
                this.posvenda.vistoriaParcial = false;                     
            },  
            
            confirmarVistoriaParcial(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalVistoriaParcialPosVenda,
                    props: {
                        id: this.posvenda.id,
                        guiaId: this.guia.id                       
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerVistoriaParcial: this.removerVistoriaParcial
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                })
            },   

            removerPrimeiroContatoVistoriaPosVenda(){
                this.posvenda.primeiraVistoria = false;                
            },              

            confirmarPrimeiroContatoVistoriaPosVenda(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrimeiroContatoVistoriaPosVenda,
                    props: {
                        id: this.posvenda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerPrimeiroContatoVistoriaPosVenda: this.removerPrimeiroContatoVistoriaPosVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },   
            
            removerPrazoInstalacaoPosVenda(){
                this.posvenda.prazoInstalacao = false;                
            },               

            confirmarPrazoInstalacaoPosVenda(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrazoInstalacaoPosVenda,
                    props: {
                        id: this.posvenda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerPrazoInstalacaoPosVenda: this.removerPrazoInstalacaoPosVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },          
            
            removerPausaInstalacaoPosVenda(){
                this.posvenda.pausarInstalacao = false;                
            },               

            confirmarPausaInstalacaoPosVenda(liberar){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalInstalacaoPausadaPosVenda,
                    props: {
                        id: this.posvenda.id,
                        liberar:liberar                       
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerPausaInstalacaoPosVenda: this.removerPausaInstalacaoPosVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },                      

            removerPrazoEntregaFuturaVenda (){
                this.venda.prazoEntregaFutura = false;                
            },               

            confirmarPrazoEntregaFuturaVenda(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrazoEntregaFuturaVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerPrazoEntregaFuturaVenda: this.removerPrazoEntregaFuturaVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },   

            removerPrazoAssinaturaVenda (){
                this.venda.prazoAssinatura = false;                
            },               

            confirmarPrazoAssinaturaVenda(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrazoAssinaturaVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerPrazoAssinaturaVenda: this.removerPrazoAssinaturaVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },    
     


            removerPrazoEntregaEletro (){
                this.venda.prazoentregaeletro = false;                
            },               

            confirmarPrazoEntregaEletro(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPrazoEntregaEletroVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerPrazoEntregaEletro: this.removerPrazoEntregaEletro
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },      

            confirmarEmEspera(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalEmEsperaGuia,
                    props: {
                        id: this.guia.id,
                        funcionarioProjetistaId :  this.guia.funcionarioProjetistaId                        
                    },
                    events: {
                        loadData: this.loadGuia
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },                
                            
            async documentoDeVenda(semAgendamento){
                this.salvandoDados = false;
                
                    
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDocumentoVenda,
                    props: {
                        guia: this.guia,
                        semAgendamento: semAgendamento                       
                    },
                    events: {
                        loadData: this.loadGuia
                    },                    
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                }) ;
                
                if(semAgendamento)
                    setTimeout(() => {this.guia.semagendamento = false;},1000);
            },    

            removerCheckListDeVenda(){
                this.venda.plantasok = false;

            },
                            
            async checkListDeVenda(){                                
                    
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDocumentoCheckListVenda,
                    props: {
                        guia: this.guia                          
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerCheckListDeVenda: this.removerCheckListDeVenda
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                }) ;
                
            },     
            
            removereletrosAnexoConcluidaVenda(){
                this.venda.eletroAnexo = false;

            },
                            
            async eletrosAnexoConcluidaVenda(){                                
                    
                this.$buefy.modal.open({
                    parent: this,
                    component: modalConcluirEletrosAnexo,
                    props: {
                        id: this.guia.id,
                        vendaId: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removereletrosAnexoConcluidaVenda: this.removereletrosAnexoConcluidaVenda
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                }) ;
                
            },     

            removerconcluirAssinaturaDigitalDeVenda(){
                this.venda.enviarAssinaturaDigital = false;

            },            
            assinaturaDigitalConcluidaVenda (){                  
                this.$buefy.modal.open({
                    parent: this,
                    component: modalConcluirAssinaturaDigitalVenda,
                    props: {                        
                        vendaId: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerconcluirAssinaturaDigitalDeVenda: this.removerconcluirAssinaturaDigitalDeVenda
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                });

            },      
            
            removerassinaturaConcluidaGerenteVenda(){
                this.venda.assinaturaGerente = false;                
            },
                            
            async assinaturaConcluidaGerenteVenda(){                                
                    
                this.$buefy.modal.open({
                    parent: this,
                    component: modalConcluirAssinaturaGerenteVenda,
                    props: {                        
                        vendaId: this.venda.id                                           
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerassinaturaConcluidaGerenteVenda: this.removerassinaturaConcluidaGerenteVenda
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                }) ;
                
            },                 
            

            removeranexarProjetoVenda(){
                this.venda.anexarProjeto = false;

            },

            
         
            async anexarProjetoVenda(){                                
                    
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAnexarProjetoVenda,
                    props: {
                        id: this.guia.id,
                        vendaId: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removeranexarProjetoVenda: this.removeranexarProjetoVenda
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                }) ;
                
            },                 

            removerconcluirAssinaturaDeVenda(){
                this.venda.assinatura = false;

            },

            
         
            async assinaturaConcluidaVenda(){                                
                    
                this.$buefy.modal.open({
                    parent: this,
                    component: modalConcluirAssinaturaVenda,
                    props: {
                        id: this.guia.id,
                        vendaId: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerconcluirAssinaturaDeVenda: this.removerconcluirAssinaturaDeVenda
                    },                    
                    hasModalCard: true,
                    trapFocus: true                    
                }) ;
                
            },     

            removerconferenciaConcluidaDeVenda(){
                this.venda.conferenciaConcluida = false;

            },
                            
            async conferenciaConcluidaDeVenda(){   
                
                if(this.venda.dataHoraConsenso !=null){

                    this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONCLUIRCONFERENCIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.conferenciaConcluida = true;
                    },
                    onCancel: () => {                        
                        this.venda.conferenciaConcluida = false;
                    },
                    })                                    

                }else{

                    

                    if(this.venda.tipo == 1){
                        this.$buefy.modal.open({
                            parent: this,
                            component: modalConferenciaConcluidaVenda,
                            props: {
                                id: this.guia.id,
                                vendaId: this.venda.id                        
                            },
                            events: {
                                loadData: this.loadGuia,
                                removerconferenciaConcluidaDeVenda: this.removerconferenciaConcluidaDeVenda
                            },                    
                            hasModalCard: true,
                            trapFocus: true                    
                        });
                    }else{

                        this.$buefy.modal.open({
                            parent: this,
                            component: modalConferenciaConcluidaTipoAssistenciaVenda,
                            props: {
                                id: this.guia.id,
                                vendaId: this.venda.id                        
                            },
                            events: {
                                loadData: this.loadGuia,
                                removerconferenciaConcluidaDeVenda: this.removerconferenciaConcluidaDeVenda
                            },                    
                            hasModalCard: true,
                            trapFocus: true                    
                        });
                    }
                }

                
            },   

            async atualizarAssinaturaDigital(){
                this.$buefy.dialog.confirm({
                    message: this.$t('ATENDIMENTO.MENSAGEMATUALIZARASSIANTURADIGITAL'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {   
                            this.salvandoDados=true;

                            const anexo = this.anexos.filter(x=>x.documentoDigital)[0];
                            const params = [];                    
                            params.push(`guiaId=${this.guia.id}`);   
                            params.push(`anexoId=${anexo.id}`);                                                            
                                                
                            try {
                                        
                                this.$http.get(`/api/venda/AtualizarSignatariosAssinaturaDigital?${params.join('&')}`)
                                .then((res) => {
                                    this.salvandoDados=false;
                                }).finally(() => {                                
                                    this.isLoading = false;                                                                                                
                                    this.loadGuia();                               
                                });                             
                                
                                
                            } catch (e) {
                                this.salvandoDados=false;                        
                                console.error(e);
                            }                                 

                        return true;
                    },
                    onCancel: () => {                                                                        
                        this.salvandoDados = false;    
                    },
                })     
            },
            
            async confirmarConclusaoAssinaturaDigital(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('ATENDIMENTO.MENSAGEMCONFIRMARCONCLUSAOASSIANTURA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {   
                            this.salvandoDados=true;
                            const params = [];                    
                            params.push(`id=${this.venda.id}`);                                                            
                                                
                            try {
                                        
                                this.$http.get(`/api/venda/AssinaturaConcluida?${params.join('&')}`)
                                .then((res) => {
                                    this.salvandoDados=false;
                                }).finally(() => {                                
                                    this.isLoading = false;                                                                                                
                                    this.$router.back();                                 
                                });                             
                                
                                
                            } catch (e) {
                                this.salvandoDados=false;                        
                                console.error(e);
                            }                                 

                        return true;
                    },
                    onCancel: () => {                                                                        
                        this.salvandoDados = false;    
                    },
                })                
            },              
             
            async confirmarReativamento(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalReativarGuia,
                    props: {
                        guia: this.guia                                              
                    },
                    events: {
                        loadData: this.loadGuia
                    },                    
                    hasModalCard: true,
                    trapFocus: true
                });             
            },              
            async confirmarDevolucaoConferenciaVenda(){
                                             
                if(!this.validacaoCampoObservacao())
                    return false;                   
                                             
                this.salvandoDados=true;
                const params = [];                    
                params.push(`id=${this.venda.id}`); 
                                                                                                
                try {
                    this.$http.get(`/api/venda/DevolucaoConferenciaVenda?${params.join('&')}`)
                            .then(res => res.data)
                            .then(data => {
                                return true;
                            }).finally(() => {      
                                this.salvandoDados=false;                                                                                
                                this.incluirObservacao();
                                this.$router.back();                                                                    
                            });                              
                    
                } catch (e) {
                    this.salvandoDados=false;                        
                    console.error(e);
                }

                return true;

                               
            }, 
            async confirmarAlteracaoProjeto(alteracaoProjeto){

                this.$buefy.modal.open({
                    parent: this,
                    component: modalAlteracaoProjeto,
                    props: {
                        id: this.guia.id,
                        alteracaoProjeto: alteracaoProjeto ?? false                                             
                    },
                    events: {
                        loadData: this.loadGuia
                    },                    
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen: true
                });                
              
            },                         
            tipoAgendamentoStyle(evento) {
                return 'color: #' + evento.tipoAgendamentoCor + '!important'
            },            
            carregarAgendaGuia(tipo,agenda) {                
                this.$http.get('/api/agenda/agendamentosGuia?id=' + this.guia.id+"&tipo="+tipo)
                        .then(res => res.data)
                        .then(data => {
                            if (data != null && data.length>0 ) {
                              
                                if(tipo == 1){
                                    this.agendamentos = data?.map( (d) => {
                                        return {
                                            id: d.id,                                        
                                            clienteId: d.clienteId,                                        
                                            tipoAgendamentoId: d.tipoAgendamentoId,
                                            tipoAgendamentoCor: d.tipoAgendamentoCor,
                                            tipoAgendamentoNome : d.tipoAgendamentoNome,
                                            agendamentoAutomatico: d.agendamentoAutomatico,
                                            dataHora: moment(d.dataHora).format('DD/MM/YYYY HH:mm:ss')
                                        }
                                    });  
                                }else if(tipo == 2){
                                    this.agendamentosMedidor = data?.map( (d) => {
                                        return {
                                            id: d.id,                                        
                                            clienteId: d.clienteId,                                        
                                            tipoAgendamentoId: d.tipoAgendamentoId,
                                            tipoAgendamentoCor: d.tipoAgendamentoCor,
                                            tipoAgendamentoNome : d.tipoAgendamentoNome,
                                            agendamentoAutomatico: d.agendamentoAutomatico,
                                            dataHora: moment(d.dataHora).format('DD/MM/YYYY HH:mm:ss')
                                        }
                                    });  
                                }else if(tipo == 3){
                                    
                                    this.agendamentosConferencia = data?.map( (d) => {
                                        return {
                                            id: d.id,                                        
                                            clienteId: d.clienteId,                                        
                                            tipoAgendamentoId: d.tipoAgendamentoId,
                                            tipoAgendamentoCor: d.tipoAgendamentoCor,
                                            tipoAgendamentoNome : d.tipoAgendamentoNome,
                                            agendamentoAutomatico: d.agendamentoAutomatico,
                                            dataHora: moment(d.dataHora).format('DD/MM/YYYY HH:mm:ss')
                                        }
                                    }); 

                                    if(this.posvenda.dataHoraPlantasInstalacao != null && this.posvenda.dataHoraVistoria ==null && this.emVistoria )
                                        this.agendamentosConferencia =   this.agendamentosConferencia.filter(x=>x.tipoAgendamentoNome=='VISTORIA' );                                    

                                    if(this.posvenda.dataHoraEntrega != null && this.posvenda.dataHoraEmInstalacao ==null )
                                        this.agendamentosConferencia =   this.agendamentosConferencia.filter(x=>x.tipoAgendamentoNome=='INSTALAÇÃO' || x.tipoAgendamentoNome=='INSTALACAO');

                                    if(this.posvenda.dataHoraInstalacaoFutura != null && this.posvenda.dataHoraEntrega ==null ){
                                        this.agendamentosConferencia =   this.agendamentosConferencia.filter(x=>x.tipoAgendamentoNome=='ENTREGA' || (x.tipoAgendamentoNome=='INSTALAÇÃO' || x.tipoAgendamentoNome=='INSTALACAO') );                                    
                                    }
                                }

                            }
                        });
            },            
            abrirCalendario(tipo,filtrarTiposAgenda, filtrarTodos,voltarTela){
                this.guia.tipoAgenda = tipo;  
                this.$router.push({ name: 'agenda',params: { guia: this.guia,tipo:tipo, filtrarTiposAgenda:filtrarTiposAgenda, filtrarTodos: filtrarTodos,voltarTela:voltarTela}  });  
            },
            confirmarValidacaoVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMVALIDARVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.validado = true;
                    },
                    onCancel: () => {                        
                        this.venda.validado = false;
                    },
                })
            },
            confirmaRevisaoVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMREVISAOVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.revisado = true;
                    },
                    onCancel: () => {                        
                        this.venda.revisado = false;
                    },
                })
            },               
            confirmarFinanceiroVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINANCEIROVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.financeiro = true;
                    },
                    onCancel: () => {                        
                        this.venda.financeiro = false;
                    },
                })
            }, 
            confirmarEletrosVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMELETROSVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.eletro = true;
                    },
                    onCancel: () => {                        
                        this.venda.eletro = false;
                    },
                })
            },    

            confirmarObrasPlantas(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMOBRASPLANTASPOSVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.confirmarObrasPlantas = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.confirmarObrasPlantas = false;
                    },
                })                
            },    
            confirmarPlantasTecnicas(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMOCONCLUIRPLANTASTECNICAS'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.confirmarPlantasTecnicas = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.confirmarPlantasTecnicas = false;
                    },
                })                
            },                
            
            confirmarFinalizacaoEntrega(){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZARENTREGA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.finalizarEntrega = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.finalizarEntrega = false;
                    },
                })                      
            },   
            
            verificaAssistenciasFinalizarInstalacao(){
                if(this.listaAssistencias.filter(x => x.dataHoraConcluido==null && x.dataHoraCancelado==null).length>0){
                    this.$buefy.dialog.confirm({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZARINSTALACAOCOMASSISTENCIAS'),
                        cancelText: 'Não',
                        confirmText: 'Sim',                        
                        onConfirm: () => {                        
                            this.finalizacaoInstalacao();
                        },
                        onCancel: () => {                        
                            return false;
                        },
                    })                     
                }else{
                    this.finalizacaoInstalacao();
                }
            },    
            removerfinalizacaoInstalacao(){
                this.posvenda.finalizarEntrega = false;  
            },
            finalizacaoInstalacao(){  
                
                if(this.posvenda.tipo == 1){

                    this.$buefy.modal.open({
                        parent: this,
                        component: modalFinalizarInstalacaoPosVenda,
                        props: {
                            id: this.guia.id,
                            posvendaId: this.posvenda.id                        
                        },
                        events: {
                            loadData: this.loadGuia,
                            removerfinalizacaoInstalacao: this.removerfinalizacaoInstalacao
                        },
                        hasModalCard: true,
                        trapFocus: true
                    })                    
                    
                }else{
                    const params = [];                    
                    params.push(`id=${this.posvenda.id}`);                                                            
                                        
                    try {                                   
                        this.$http.get(`/api/posvenda/FinalizarInstalacao?${params.join('&')}`)
                        .then((res) => {
                            return true;
                        }).finally(() => {                                                            
                            this.$router.back();                                 
                        });                                                                             
                    } catch (e) {                        
                        console.error(e);
                    }
                }


            },

            removerAnexoCertificadoPosVenda(){
                this.posvenda.anexarCertificado = false;  
            },
            confirmarAnexoCertificadoPosVenda(){                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalAnexarCertificadoPosVenda,
                    props: {
                        id: this.guia.id,
                        posvendaId: this.posvenda.id                                                
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerAnexoCertificadoPosVenda: this.removerAnexoCertificadoPosVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },

            confirmarFinalizacaoSemCertificadoInstalacao(){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZARINSTALACAOSEMCERTIFICADO'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                                                
                        this.salvandoDados=true;
                        const params = [];                                            
                        params.push(`id=${this.posvenda.id}`);                                                                          
                                                                        
                        try {
                            this.$http.get(`/api/posvenda/FinalizarInstalacaoSemCertificado?${params.join('&')}`)
                                    .then(res => res.data)
                                    .then(data => {
                                        return true;
                                    }).finally(() => {      
                                        this.salvandoDados=false;                                                                                
                                        this.$router.back();                                                                    
                                    });                              
                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }

                        return true;                        
                    },
                    onCancel: () => {                        
                        this.posvenda.finalizarInstalacaoSemCertificado = false;
                    },
                })                      
            },   
            confirmarFecharAssistencia(item){

                this.$buefy.dialog.confirm({
                    title: this.$t('SISTEMA.ATENCAO'),
                    message: this.$t('ATENDIMENTO.MENSAGEMCONFIRMARFECHARASSISTENCIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim', 
                    type:"is-info",                      
                    hasIcon: true,                                         
                    onConfirm: () => {   

                        this.salvandoDados=true;
                        const params = [];                                                                                                                             
                        params.push(`posvendaId=${this.posvenda.id}`);                         
                    
                        this.$http.get(`/api/posvenda/FecharAssistencia?${params.join('&')}`)
                        .then((res) => {
                            this.salvandoDados=false;
                        }).finally(() => {                                
                            this.salvandoDados=false;
                            this.$router.back();      
                        });                          
                            
                    },
                    onCancel: () => {   
                        item.fecharAssistencia = false;                                                                     
                        this.salvandoDados = false;    
                    },
                })                  
                
                return true;
            },                       
            confirmarFinalizacaoInstalacao(){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZARINSTALACAO'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.finalizarInstalacao = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.finalizarInstalacao = false;
                    },
                })                      
            },  
            confirmarInstalacaoSemAgendamento(){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMINSTALACAOSEMAGENDAMENTO'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.salvandoDados=true;
                        const params = [];                                            
                        params.push(`id=${this.posvenda.id}`);                                                 
                        params.push(`guiaId=${this.guia.id}`);   
                                                                        
                        try {
                            this.$http.get(`/api/posvenda/InstalacaoSemAgendamento?${params.join('&')}`)
                                    .then(res => res.data)
                                    .then(data => {
                                        return true;
                                    }).finally(() => {      
                                        this.salvandoDados=false;                                                                                
                                        this.$router.back();                                                                    
                                    });                              
                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }

                        return true;
                    },
                    onCancel: () => {                        
                        this.posvenda.instalacaoSemAgendamento = false;
                    },
                })                      
            },                           
            confirmarFinalizacaoEnvioFabrica(){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZARENVIOFABRICA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.finalizarEnviarFabrica = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.finalizarEnviarFabrica = false;
                    },
                })                      
            },     
        
            confirmarterminarPosvenda(){
                
                if(!this.posvenda.certificado){

                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.ANEXARCERTIFICADOSBRIGATORIO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMANEXARCERTIFICADOSBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    setTimeout(() => {this.posvenda.terminarPosvenda = false;},500);                    

                }else if(this.listaAssistencias.filter(x => x.dataHoraConcluido==null &&  x.dataHoraCancelado==null ).length>0){
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.OBRASPLANTASBRIGATORIO'),
                        message: this.$t('GUIAATENDIMENTO.OBRIGATORIOCONCLUIRASSISTENCIAS'),
                        type: 'is-warning',
                        hasIcon: true
                    });
                    
                    setTimeout(() => {this.posvenda.terminarPosvenda = false;},500);                    
                }else{
                    this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZARPOSVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.terminarPosvenda = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.terminarPosvenda = false;
                    },
                })    
                }


                  
            },              
            confirmarFinalizacaoEFinance(){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZAREFINANCE'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.finalizarEfinance = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.finalizarEfinance = false;
                    },
                })                      
            },     
            
            removerobranaoconformevendedor(){
                this.posvenda.obranaoconforme = false;                
            },              

            confirmarObraNaoConformeVendedorPosVenda(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObraNaoConformeVendedorPosVenda,
                    props: {
                        id: this.posvenda.id,
                        guiaId: this.guia.id,                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerobranaoconformevendedor: this.removerobranaoconformevendedor
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },                
            
            removerobranaoconforme(){
                this.posvenda.obranaoconforme = false;                
            },              

            confirmarObraNaoConformePosVenda(){
                
                this.$buefy.modal.open({
                    parent: this,
                    component: modalObraNaoConfirmidadePosVenda,
                    props: {
                        id: this.posvenda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerobranaoconforme: this.removerobranaoconforme
                    },
                    hasModalCard: true,
                    trapFocus: true
                })

            },               
                      
            confirmarConclusaoVistoria(){
                
                
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONCLUIRIVISTORIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.concluirVistoria = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.concluirVistoria = false;
                    },
                })                
            },  

            removerFinalizacaoInstalacaoFuturaParcial(){
                this.posvenda.finalizarInstalacaoFuturaParcial = false;                     
            },  
            
            confirmarFinalizacaoInstalacaoFuturaParcial(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalEntregaParcialPosVenda,
                    props: {
                        id: this.posvenda.id,
                        guiaId: this.guia.id                      
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerFinalizacaoInstalacaoFuturaParcial: this.removerFinalizacaoInstalacaoFuturaParcial
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            },   

            confirmarFinalizacaoInstalacaoFutura(posvenda){
                
                if(!posvenda.faturado){
                    this.$buefy.dialog.alert({
                        title: this.$t('SISTEMA.ATENCAO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMFATURADO'),
                        type: 'is-info',
                        hasIcon: true
                    });
                   
                    setTimeout(() => { posvenda.finalizarInstalacaoFutura = false;},500);
                    return true;
                }       

                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONCLUIRINSTALACAOFUTURA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.posvenda.finalizarInstalacaoFutura = true;
                    },
                    onCancel: () => {                        
                        this.posvenda.finalizarInstalacaoFutura = false;
                    },
                })                
            },                                                     
            confirmarMedidorVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMMEDIDORVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.medidor = true;
                    },
                    onCancel: () => {                        
                        this.venda.medidor = false;
                    },
                })
            }, 
            confirmarConferenciaAutorizadaVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONFERENCIAAUTORIZADAVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.conferenciaAutorizada = true;
                    },
                    onCancel: () => {                        
                        this.venda.conferenciaAutorizada = false;
                    },
                })
            }, 

            
            removerConferenciaConcluidaParcialVenda (){
                this.venda.conferenciaConcluidaParcial = false;                
            },  
            
            confirmarConferenciaConcluidaParcialVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalConferenciaConcluidaParcialVenda,
                    props: {
                        id: this.venda.id,
                        guiaId: this.guia.id 
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerConferenciaConcluidaParcialVenda: this.removerConferenciaConcluidaParcialVenda
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            },                
            
                  
            devolucaoConferenciaVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMDEVOLVERCONFERENCIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.devolverConferencia = true;
                    },
                    onCancel: () => {                        
                        this.venda.devolverConferencia = false;
                    },
                })
            },                 
                    
            confirmarEnvioProducaoVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMENVIOPRODUCAOEVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.envioProducao = true;
                    },
                    onCancel: () => {                        
                        this.venda.envioProducao = false;
                    },
                })
            }, 
            confirmarFinalizarEfinanceVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMFINALIZAREFINANCEVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.finalizarEfinance = true;
                    },
                    onCancel: () => {                        
                        this.venda.finalizarEfinance = false;
                    },
                })
            }, 
            confirmarPlantasVenda(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMPLANTASVENDA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.venda.plantasok = true;
                    },
                    onCancel: () => {                        
                        this.venda.plantasok = false;
                    },
                })
            },  
            
            desabilitarconfirmarConcluirVistoriaMedicao (){
                this.venda.removerDataEntregaFutura = false;                
            },

            confirmarConcluirVistoriaMedicao(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalConcluirVistoriaMedicao,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        desabilitarconfirmarConcluirVistoriaMedicao: this.desabilitarconfirmarConcluirVistoriaMedicao
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                 
            
            desabilitardevolverconferenteVistoria (){
                this.venda.devolverconferenteVistoria = false;                
            },

            devolverconferenteVistoriaMedicao(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolverConferenteVistoriaMedicao,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        desabilitardevolverconferenteVistoria: this.desabilitardevolverconferenteVistoria
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                 
                        

            desabilitarRemoverDataEntregaFuturaVenda (){
                this.venda.removerDataEntregaFutura = false;                
            },

            confirmarRemoverDataEntregaFuturaVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRemoverEntregaFuturaVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        desabilitarRemoverDataEntregaFuturaVenda: this.desabilitarRemoverDataEntregaFuturaVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },       
            
            desabilitarConcluidoPlantasEntregaFuturaVenda(){
                this.venda.plantasConcluido = false;                
            },            
            concluidoPlantasEntregaFuturaVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalConcluidoPlantasEntregaFuturaVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        desabilitarConcluidoPlantasEntregaFuturaVenda: this.desabilitarConcluidoPlantasEntregaFuturaVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },             
            
            desabilitarconfirmarsolicitarVistoriaVenda (){
                this.venda.solicitarVistoria = false;                
            },            
            confirmarsolicitarVistoriaVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalSolicitarVistoriaMedicao,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        desabilitarconfirmarsolicitarVistoriaVenda: this.desabilitarconfirmarsolicitarVistoriaVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },               
            desabilitarRemoverDataEntregaFuturaPlantasVenda (){
                this.venda.removerDataEntregaFuturaPlantas = false;                
            },            
            confirmarRemoverDataEntregaFuturaPlantasVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalRemoverEntregaFuturaPlantasVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        desabilitarRemoverDataEntregaFuturaPlantasVenda: this.desabilitarRemoverDataEntregaFuturaPlantasVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },               
            excluirAmbiente(ammbienteId){
                this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONFIRMAEXCLUSAOAMBIENTE'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                                             
                        try {                                
                                this.$http.get(`/api/atendimento/ExcluirAmbiente?id=${ammbienteId}`)
                                .then((res) => {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: this.$t('SISTEMA.SALVOSUCESSO'),
                                        type: 'is-success'
                                    })
                                }).finally(() => {                                
                                    this.isLoading = false;    
                                    this.loadGuia();                                                                                                    
                                });                             
                                
                            } catch (e) {
                                this.salvandoDados=false;                        
                                console.error(e);
                            }                           
                                                                       
                    },
                    onCancel: () => {                        
                        return true;
                    },
                })
            },
            excluirAssistencia(assistenciaId){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONFIRMAEXCLUSAOASSISTENCIA'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        try {
                                
                            this.$http.get(`/api/assistencia/CancelarAssistencia?id=${assistenciaId}`)
                            .then((res) => {
                                this.$buefy.toast.open({
                                    duration: 5000,
                                    message: this.$t('SISTEMA.SALVOSUCESSO'),
                                    type: 'is-success'
                                })
                            }).finally(() => {                                
                                this.isLoading = false;    
                                this.loadGuia();                                                                                                    
                            });                             
                            
                        } catch (e) {
                            this.salvandoDados=false;                        
                            console.error(e);
                        }                        
                    },
                    onCancel: () => {                        
                        return true;
                    },
                })
            },
            incluirAssistencia(assistenciaId){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalCadastroAssistenciaPosVenda,
                    props: {
                        id: assistenciaId,
                        posvendaid: this.posvenda.id,
                        clienteCodigoExterno: this.guia.clienteCodigoExterno ,
                        funcionarioConferenteId: this.posvenda.funcionarioConferenteId,
                        guiaId: this.guia.id                       
                    },
                    events: {
                        carregarAssistencias: this.carregarAssistencias                        
                    },
                    hasModalCard: true,
                    trapFocus: true,
                    fullScreen:true
                })
            },               

            removerDevolucaoAssinaturaVenda (){
                this.venda.devolverAssinatura = false;                
            },            
            confirmarDevolucaoAssinaturaVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoAssinaturaVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoAssinaturaVenda: this.removerDevolucaoAssinaturaVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            }, 
            removerconfirmartransformaentregafutura (){
                this.venda.transformaentregafutura = false;                
            },            
            confirmartransformaentregafutura(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalTransformaEntregaFuturaVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerconfirmartransformaentregafutura: this.removerconfirmartransformaentregafutura
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            }, 
            removerconfirmartransformaentregafuturaplantas (){
                this.venda.transformaentregafuturaplantas = false;                
            },            
            confirmartransformaentregafuturaplantas(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalTransformaEntregaFuturaPlantasVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerconfirmartransformaentregafuturaplantas: this.removerconfirmartransformaentregafuturaplantas
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                         
            removerconfirmarDevolucaoInstalacaoFutura (){
                this.venda.devolverInstalacaoFutura = false;                
            },            
            confirmarDevolucaoInstalacaoFutura(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoInstalacaoFuturaPosVenda,
                    props: {
                        id: this.posvenda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerconfirmarDevolucaoInstalacaoFutura: this.removerconfirmarDevolucaoInstalacaoFutura
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            }, 
            removerDevolucaoGerenteConferenciaVenda (){
                this.venda.devolverGerenteConferencia = false;                
            },            
            confirmarDevolucaoGerenteConferenciaVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoGerenteConferenciaVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoGerenteConferenciaVenda: this.removerDevolucaoGerenteConferenciaVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                
            removerDevolucaoEletrosVenda (){
                this.venda.devolverEletros = false;                
            },            
            confirmarDevolucaoEletrosVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoEletrosVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoEletrosVenda: this.removerDevolucaoEletrosVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },  
            

            removerDevolucaoPlantasVenda (){
                this.posvenda.devolverPlantas = false;                
            },            
            confirmarDevolucaoPlantasVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoPlantasVenda,
                    props: {
                        id: this.posvenda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoPlantasVenda: this.removerDevolucaoPlantasVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },  

            removermodalDevolucaoPlantasImpressaoVenda(){
                this.posvenda.devolverPlantasImpressao = false;                
            },            
            confirmarDevolucaoPlantasImpressaoVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoPlantasImpressaoVenda,
                    props: {
                        id: this.guia.id,
                        posvendaId: this.posvenda.id                                 
                    },
                    events: {
                        loadData: this.loadGuia,
                        removermodalDevolucaoPlantasImpressaoVenda: this.removermodalDevolucaoPlantasImpressaoVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },              

            removerDevolucaoMedidorVenda (){
                this.venda.devolverMedidor = false;                
            },            
            confirmarDevolucaoMedidorVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoMedidorVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoMedidorVenda: this.removerDevolucaoMedidorVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },            
            removerDevolucaoFinanceiroVenda (){
                this.venda.devolverFinanceiro = false;                
            },            
            confirmarDevolucaoFinanceiroVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoFinanceiroVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoFinanceiroVenda: this.removerDevolucaoFinanceiroVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                           
            removerDevolucaoEfetivacaoVenda (){
                this.venda.devolver = false;                
            },
            confirmarDevolucaoEfetivacaoVenda(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoEfetivacaoVenda,
                    props: {
                        id: this.venda.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoEfetivacaoVenda: this.removerDevolucaoEfetivacaoVenda
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                     
            confirmarFinalizacaoProjeto(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.MENSAGEMCONFIRMARPROJETO'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {                        
                        this.guia.projeto = true;
                    },
                    onCancel: () => {                        
                        this.guia.projeto = false;
                    },
                })
            },

            removerDevolucaoProjeto (){
                this.guia.devolver = false;                
            },
            confirmarDevolucaoProjeto(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalDevolucaoProjetistaGuia,
                    props: {
                        id: this.guia.id                        
                    },
                    events: {
                        loadData: this.loadGuia,
                        removerDevolucaoProjeto: this.removerDevolucaoProjeto
                    },
                    hasModalCard: true,
                    trapFocus: true
                })
            },                                         
            confirmarAutorizacaoProjeto(){
                 this.$buefy.dialog.confirm({
                    message: this.$t('GUIAATENDIMENTO.CONFIRMARAUTORIZACAO'),
                    cancelText: 'Não',
                    confirmText: 'Sim',                        
                    onConfirm: () => {
                        this.guia.dataHoraAutorizacao = new Date();
                        this.guia.autorizacao = true;
                    },
                    onCancel: () => {
                        this.guia.dataHoraAutorizacao = null;
                        this.guia.autorizacao = false;
                    },
                })
            },

            confirmarSemAgendamento(){
                this.documentoDeVenda(true);
            },    
            
            notificarConclusaoEnvioProducao() {
                this.$http.get('/api/venda/NotificarConclusaoEnvioProducao?guiaId=' + this.guia.id)
                    .then(res => {
                        return true;
                    })
            },              
            loadHistoricoAlteracoes() {
                this.$http.get('/api/atendimento/GuiaObservacoes?id=' + this.guia.id)
                    .then(res => {
                        this.historicoAlteracoes = res.data
                        
                        if(this.filtroObservacoes != 'Todos')
                            this.historicoAlteracoes = this.historicoAlteracoes.filter(x=>x.tipo === this.filtroObservacoes); 
                                                    
                    })
            },            
            incluirObservacao(){                
                const guiaObservacao = {
                    guiaId: this.guia.id,
                    observacao : this.guia.observacoes
                }                
                this.isLoading = true;    
                this.$http.post('/api/atendimento/guiaObservacoes', guiaObservacao)
                    .then(res => {

                        return true;

                    }).finally(() => {                                
                        this.isLoading = false;    
                        this.guia.observacoes = null;                        
                        this.loadHistoricoAlteracoes();
                    });                
            },

            confirmarAnexo(){
                this.$buefy.modal.open({
                    parent: this,
                    component: modalPreencherTipoAnexo,
                    events: {
                        loadData: this.loadAnexos,
                        inlcuirAnexo: this.incluirAnexo                        
                    },
                    hasModalCard: true,
                    trapFocus: true
                })  
            },   

            incluirAnexo(tipoAnexoId) {

                const formData = new FormData()
                formData.append('id', this.guia.id);
                formData.append('type', 'guia');      
                formData.append('tipoAnexoSelecionadoId', tipoAnexoId);                                             
                formData.append('anexo', this.fileList, this.fileList.name);
                               
                this.isLoading = true;                    
                this.$http.post('/api/atendimento/anexoGuia', formData, {
                    progress(e) {
                        return true;
                    }
                }).then(response => {                    
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: response.data.sucesso,
                        type: 'is-success'
                    })
                    this.loadAnexos();
                    this.loadHistoricoAlteracoes();
                    this.isLoading = false;                    
                }, error => {
                    this.$buefy.toast.open({
                        duration: 5000,
                        message: error.message,
                        type: 'is-danger'
                    })
                });
       
            },
            loadAnexos() {              
                this.fileList = null;                  
                this.anexos = null
                this.$http.get('/api/atendimento/anexos?id=' + this.guia.id)
                    .then(res => {
                        if(res.data){
                            this.anexos = res.data  
                            if(this.anexos!=null)                                              
                                this.venda.anexos = this.anexos.length;
                        }

                    })
            },  
            loadAmbientes() {              
                this.$http.get('/api/atendimento/ambientes?id=' + this.guia.id)
                    .then(res => res.data)
                    .then(data => {
                        this.ambientes = data;

                    }).finally(() => {                                
                        this.guia.numeroAmbiente = this.ambientes.length;        
                    });

                    
            },              
            editarAnexo(anexo)    {

                this.$buefy.modal.open({
                    parent: this,
                    component: modalEditarAnexo,
                    props: {
                        anexo: anexo                        
                    },
                    events: {
                        loadData: this.loadAnexos                        
                    },
                    hasModalCard: true,
                    trapFocus: true
                })                

            },
            removerAnexoDigital(id){
                const anexo = this.anexos.filter(x => x.id == id)[0]
                this.$buefy.dialog.confirm({
                    title: this.$t('ANEXOS.EXCLUIRANEXO'),
                    message: this.$t('ANEXOS.CONFIRMAEXCLUSAO') + anexo.fileName.replace(/^.*[\\/]/, '') + '</b> ?',
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        const removerAnexo = {
                            tipo: "guia",
                            anexoId: id
                        }

                        this.isLoading = true;    
                        this.$http.get('/api/venda/CancelarAssinaturaDigital?anexoId='+id+'&guiaId='+this.guia.id+'&vendaId='+this.venda.id)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadGuia();
                                    this.loadAnexos();
                                }
                            }).finally(() => {                                
                                this.isLoading = false;  
                                this.loadHistoricoAlteracoes();  
                            });
                            
                    }
                })
            },
            removerAnexo(id) {
                const anexo = this.anexos.filter(x => x.id == id)[0]
                this.$buefy.dialog.confirm({
                    title: this.$t('ANEXOS.EXCLUIRANEXO'),
                    message: this.$t('ANEXOS.CONFIRMAEXCLUSAO') + anexo.fileName.replace(/^.*[\\/]/, '') + '</b> ?',
                    confirmText: this.$t('SISTEMA.CONFIRMAR'),                    
                    cancelText: this.$t('SISTEMA.CANCELAR'),                      
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {

                        const removerAnexo = {
                            tipo: "guia",
                            anexoId: id
                        }

                        this.isLoading = true;    
                        this.$http.post('/api/atendimento/excluirAnexoGuia', removerAnexo)
                            .then((res) => {
                                if (res.body.erro) {
                                    this.$buefy.toast.open({
                                        duration: 5000,
                                        message: res.body.erro,
                                        type: 'is-danger'
                                    })
                                } else {
                                    this.loadAnexos()
                                }
                            }).finally(() => {                                
                                this.isLoading = false;  
                                this.loadHistoricoAlteracoes();  
                            });
                            
                    }
                })
            },            
            visualizarAnexo(anexoId, fileName) {                
                window.open('/api/atendimento/anexoPdf?id=' + this.guia.id + '&anexoId=' + anexoId + '&fileName=' + fileName);
            },
            visualizarAnexoDigital(id) {                
                this.isLoading = true;    
                this.$http.get('/api/venda/DownloadAssinaturaDigital?anexoId='+id+'&guiaId='+this.guia.id)
                    .then((res) => {
                        if (res.body.erro) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.body.erro,
                                type: 'is-danger'
                            })
                        } else {
                            window.open(res.data);
                        }
                        this.isLoading = false; 
                    });
            },  
            gerarLinkAssinaturaDigital(id) {                
                this.isLoading = true;    
                this.$http.get('/api/venda/LinkAssinaturaDigital?signatarioId='+id+'&guiaId='+this.guia.id)
                    .then((res) => {
                        if (res.body.erro) {
                            this.$buefy.toast.open({
                                duration: 5000,
                                message: res.body.erro,
                                type: 'is-danger'
                            })
                        } else {
                            window.open(res.data);
                        }
                        this.isLoading = false; 
                    });
            },                             
            loadDataPosVenda(guiaId){                
                this.isLoading=true;
                this.$http.get('/api/posvenda/RetornaPosVendaPorGuiaId?guiaId=' + this.guia.id)
                        .then(res => res.data)
                        .then(data => {

                            if(data && data !=""){
                                this.posvenda = data;                                
                                this.posvenda.dataHoraCadastro = this.$moment(data.dataHoraCadastro).toDate();

                                if(this.posvenda.dataHoraObrasPlantas)
                                    this.posvenda.dataHoraObrasPlantas = this.$moment(data.dataHoraObrasPlantas).toDate();

                            }

                        }).finally(() => {
                            this.isLoading = false;
                            this.carregarAgendaGuia(3); 
                            this.carregarAssistencias();   
                        });
            },  
            carregarAssistencias(){
                this.isLoading=true;
                this.$http.get('/api/assistencia/retornarPorPosVendaId?id=' + this.posvenda.id)
                        .then(res => res.data)
                        .then(data => {

                            if (data != null && data.length>0 ) {
                                
                                this.listaAssistencias = data?.map( (d) => {
                                        return {
                                            id: d.id,                                        
                                            clienteId: d.clienteId,                                        
                                            titulo: d.titulo,
                                            observacao: d.observacao,
                                            dataHoraCadastro: moment(d.dataHoraCadastro).format('DD/MM/YYYY HH:mm:ss'),
                                            funcionarioConferenteNome: d.funcionarioConferenteNome,                                            
                                            custo: d.custoNome,
                                            eList: d.eList,
                                            faturado: d.faturado,
                                            dataHoraAssistenciaTecnica: (d.dataHoraAssistenciaTecnica) ? moment(d.dataHoraAssistenciaTecnica).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraEnviarFabrica: (d.dataHoraEnviarFabrica) ? moment(d.dataHoraEnviarFabrica).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraFinalizarEfinance: (d.dataHoraFinalizarEfinance) ? moment(d.dataHoraFinalizarEfinance).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraConcluido: (d.dataHoraConcluido) ? moment(d.dataHoraConcluido).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraCancelado: (d.dataHoraCancelado) ? moment(d.dataHoraCancelado).format('DD/MM/YYYY HH:mm:ss') : null, 
                                            dataHoraPrazo: (d.dataHoraPrazo) ? moment(d.dataHoraPrazo).format('DD/MM/YYYY') : null,
                                            dataHoraLiberacaoEntrega: (d.dataHoraLiberacaoEntrega) ? moment(d.dataHoraLiberacaoEntrega).format('DD/MM/YYYY') : null,   
                                            dataHoraAgendamento: (d.dataHoraAgendamento) ? moment(d.dataHoraAgendamento).format('DD/MM/YYYY') : null,                                               
                                            codigoLote: d.codigoLote,                                         
                                            posics: d.posics,
                                            atraso: d.atraso                                             
                                        }
                                });                                                                                   
                            }                            

                        }).finally(() => {
                            this.isLoading = false;                             
                        });                
            },        
            loadDataVenda(guiaId){
                this.isLoading=true;
                this.$http.get('/api/venda/retornaVendaPorGuiaId?guiaId=' + this.guia.id)
                        .then(res => res.data)
                        .then(data => {

                            if(data && data !=""){
                                this.venda = data;                                
                                this.venda.dataHoraCadastro = this.$moment(data.dataHoraCadastro).toDate();

                                if(this.venda.dataHoraRevisao)
                                    this.venda.dataHoraRevisao = this.$moment(data.dataHoraRevisao).toDate();

                                if(this.venda.dataHoraValidacao)
                                    this.venda.dataHoraValidacao = this.$moment(data.dataHoraValidacao).toDate();

                                if(this.venda.dataHoraFinanceiro)
                                    this.venda.dataHoraFinanceiro = this.$moment(data.dataHoraFinanceiro).toDate();                                

                                if(this.venda.captador >0)
                                    this.venda.opcaocaptador = true;
                            }

                        }).finally(() => {
                            this.isLoading = false;
                            this.carregarAgendaGuia(2);    
                        });
            },            
            mudouTabPrincipal(value) {         
                if (value == 'posvenda') {
                    this.loadDataPosVenda(this.guia.id);
                }else if (value == 'venda') {
                    this.loadDataVenda(this.guia.id);
                } 
                
            },              
            mudouObsTab(value) {         
                return true;
            },   
            async JaSeExisteAtendimentoCliente(){

                const statusFiltro = 0; 
                const page = 1;
                const ordenarPor = "DataUltimaModificacao";
                const ordenar = "desc";

                const params = [                                        
                    `unidadeId=${this.unidadeId}`,                    
                    `ordem=${ordenarPor}.${ordenar}`,
                    `page=${page}`,
                    `clienteId=${this.guia.clienteId}`,
                    `status=${statusFiltro}`
                ];

                let retorno = false;

                this.salvandoDados = true;                
                await this.$http.get(`/api/atendimento/procurar?${params.join('&')}`)
                    .then(({ data }) => {
                        this.salvandoDados = false                        
                        retorno  = (data.lista.length > 0);                                                                                                             
                    })
                    .catch((error) => {
                        
                        throw error
                    }).finally(() => {                                
                        return retorno;
                    });                
                  
                 return retorno; 
                           
            },                             
            validacoes(){

                //** VALIDAÇÕES POSVENDA  **/
                if(this.tabsPrincipal == "posvenda"){                                        
                    
                    //Finalizar Em instalacao
                    if(this.posvenda.finalizarInstalacao){
                        this.verificaAssistenciasFinalizarInstalacao();                   
                        return;                    
                    }                          
                }else if(this.tabsPrincipal == "venda"){ //** VALIDAÇÕES VENDA **/
                                             
              
                            //Alterações de projeto etapa assinatura de Projetos
                            if(this.venda.alteracaoAssinatura){
                                this.confirmarAlteracaoProjeto(true);                   
                                return;                    
                            } 

                            //Devolver para conferencia
                            if(this.venda.devolverConferencia){
                                this.confirmarDevolucaoConferenciaVenda();
                                this.salvandoDados = false;
                                return;
                            }  

                            

                            // Após escolher o confernte, momento que seta a data de autorização da  conferencia 
                            if(this.venda.funcionarioConferenteId > 0 && this.venda.dataHoraConferenciaAutorizada == null && this.venda.dataHoraMedidor !=null){

                                if(this.venda.prazoConferenciaEmDias > 0){
                                    this.venda.dataHoraConferenciaAutorizada = new Date();                                                                        
                                }else{
                                    this.$buefy.dialog.alert({
                                        title: this.$t('GUIAATENDIMENTO.PRAZOPARACONCLUSAOCONFERENCIAOBRIGATORIO'),
                                        message: this.$t('GUIAATENDIMENTO.MENSAGEMPRAZOPARACONCLUSAOCONFERENCIAOBRIGATORIO'),
                                        type: 'is-warning',
                                        hasIcon: true
                                    });            
                                    this.salvandoDados = false;            
                                    return;
                                }                                
                            }
                                                                                                                     
                //** VALIDAÇÕES ATENDIMENTO **/
                }else if(this.tabsPrincipal == "atendimento"){

                            if (this.guia.funcionarioComercialId == 0 || this.guia.funcionarioComercialId == null 
                            || this.guia.funcionarioComercialId == '' || this.guia.funcionarioComercialId == undefined) {
                                this.$buefy.dialog.alert({
                                    title: this.$t('GUIAATENDIMENTO.VENDEDOROBRIGATORIO'),
                                    message: this.$t('GUIAATENDIMENTO.MENSAGEMVENDEDOROBRIGATORIO'),
                                    type: 'is-warning',
                                    hasIcon: true
                                });

                                this.salvandoDados = false;
                                return;
                            }       

                            //Guia ja criada mensagem de campos obrigatorios
                            if (this.guia.id > 0 && (this.guia.temperatura==undefined || this.guia.prazoVendedorEmDias <=0 || this.guia.prazoVendedorEmDias >20) 
                            && this.guia.dataHoraAutorizacao==null ) {
                                this.$buefy.dialog.alert({
                                    title: this.$t('GUIAATENDIMENTO.VENDEDORCAMPOSOBRIGATORIO'),
                                    message: this.$t('GUIAATENDIMENTO.MENSAGEMVENDEDORCAMPOSOBRIGATORIO'),
                                    type: 'is-warning',
                                    hasIcon: true
                                });

                                this.salvandoDados = false;
                                return;
                            }   
                            
                            //Quando ao cadastrar guia, o usuario entra com prioridade, mensagem de campos obrigatorios
                            if (this.guia.id == 0 && this.guia.temperatura>0 
                            && (this.guia.temperatura==undefined || this.guia.prazoVendedorEmDias <=0 )  ) {
                                this.$buefy.dialog.alert({
                                    title: this.$t('GUIAATENDIMENTO.VENDEDORCAMPOSOBRIGATORIO'),
                                    message: this.$t('GUIAATENDIMENTO.MENSAGEMVENDEDORCAMPOSOBRIGATORIO'),
                                    type: 'is-warning',
                                    hasIcon: true
                                });

                                this.salvandoDados = false;
                                return;
                            }                
                            

                            // Após escolher o projetista, momento que seta a data de autorização do atendimento 
                            if((this.guia.funcionarioProjetistaId > 0 && this.guia.dataHoraAutorizacao == null && this.guia.dataHoraPrioridade !=null)){

                                if((this.guia.prazoProjetoEmDias > 0 && this.guia.dataHoraVendedor !=null) || this.guia.funcionarioProjetistaId == 51){
                                    this.guia.dataHoraAutorizacao = new Date();       
                                    this.guia.funcionarioAutorizacaoId = this.usuario.funcionarioId;                                     
                                }else if (this.guia.dataHoraVendedor == null) {
                                    this.$buefy.dialog.alert({
                                        title: this.$t('GUIAATENDIMENTO.PROJETOCONFIRMADOOBRIGATORIO'),
                                        message: this.$t('GUIAATENDIMENTO.MENSAGEMPROJETOCONFIRMADOOBRIGATORIO'),
                                        type: 'is-warning',
                                        hasIcon: true
                                    });            
                                    this.salvandoDados = false;            
                                    return;                                    
                                }else  {
                                    this.$buefy.dialog.alert({
                                        title: this.$t('GUIAATENDIMENTO.PRAZOPARACONCLUSAOOBRIGATORIO'),
                                        message: this.$t('GUIAATENDIMENTO.MENSAGEMPRAZOPARACONCLUSAOOBRIGATORIO'),
                                        type: 'is-warning',
                                        hasIcon: true
                                    });            
                                    this.salvandoDados = false;            
                                    return;
                                }
                                
                            }   

                            // Momento que seta a data do projeto como concluido
                            if(this.guia.funcionarioProjetistaId>0 && this.guia.dataHoraProjeto == null &&  this.guia.projeto){
                                this.guia.dataHoraProjeto = new Date();                
                            }

                            if(this.negociacao !=null && this.guia.dataHoraCancelado == null && this.guia.status ==2){
                                this.confirmarCancelamento(true);                   
                                this.salvandoDados = false;
                                return;
                            }

                            if(this.negociacao !=null && this.guia.dataHoraCancelado == null && this.guia.status ==3){
                                this.confirmarEmEspera();                   
                                this.salvandoDados = false;
                                return;
                            }                

                            if(this.negociacao !=null && this.guia.dataHoraVenda == null && this.guia.status ==1){
                                this.documentoDeVenda();                   
                                return;
                            }   
                            
                            if(this.negociacao !=null && this.negociacao=="alteracao" && this.guia.status ==0){
                                this.confirmarAlteracaoProjeto();                   
                                return;
                            }      
                                                            
                }          
                                
                if(!this.validacaoCampoObservacao())
                    return false;


                return true;

            },   
            validacaoCampoObservacao(){
                //** VALIDAÇÃO OBSERVACAO OBRIGATORIA**/
                if (this.guia.observacoes== null  || this.guia.observacoes == '' || this.guia.observacoes == undefined) {
                    this.$buefy.dialog.alert({
                        title: this.$t('GUIAATENDIMENTO.OBSERVACAOOBRIGATORIO'),
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMOBSERVACAOOBRIGATORIO'),
                        type: 'is-warning',
                        hasIcon: true
                    });

                    this.salvandoDados = false;
                    return false;
                }
                
                return true;
            }, 
            async salvarGuia() {  
                //Validar quando for criar alguma guia, se ja existe algum cliente em andamento
                const jaExisteAtendimento = await this.JaSeExisteAtendimentoCliente();                
                if(this.guia.id <=0 && jaExisteAtendimento){                      
                    this.$buefy.dialog.confirm({
                        message: this.$t('GUIAATENDIMENTO.MENSAGEMCLIENTEDUPLICADO'),
                        cancelText: 'Não',
                        confirmText: 'Sim',                        
                        onConfirm: () => {                        
                            if (this.validacoes()){                                    
                                if(this.tabsPrincipal == "posvenda"){
                                    this.enviaSalvarPosVenda();  
                                } else if(this.tabsPrincipal == "venda"){
                                    this.enviaSalvarVenda();                                
                                }else if(this.tabsPrincipal == "atendimento"){
                                    this.enviaSalvarGuia();                                
                                }                    
                            }  
                        },
                        onCancel: () => {                        
                            return true;
                        },
                    })                    
                }else{
                    if (this.validacoes()){                                    
                            if(this.tabsPrincipal == "posvenda"){
                                this.enviaSalvarPosVenda();  
                            } else if(this.tabsPrincipal == "venda"){
                                this.enviaSalvarVenda();                                
                            }else if(this.tabsPrincipal == "atendimento"){
                                this.enviaSalvarGuia();                                
                            }                   
                    } 
                }
                                                                  
            },
            enviaSalvarPosVenda() {
                                         
                
                this.salvandoDados = true;
                // Momento que seta a data de obras plantas como concluido
                if(this.posvenda.dataHoraPlantasInstalacao == null && this.posvenda.confirmarPlantasTecnicas)
                    this.posvenda.dataHoraPlantasInstalacao = new Date();   

                // Momento que seta a data de obras plantas como concluido
                if(this.posvenda.dataHoraObrasPlantas == null && this.posvenda.confirmarObrasPlantas)
                    this.posvenda.dataHoraObrasPlantas = new Date();   

                // Momento que seta a data de vistoria como concluido
                if(this.posvenda.dataHoraVistoria == null && this.posvenda.concluirVistoria){
                    this.posvenda.dataHoraVistoria = new Date();  
                    this.atualizarPosics();
                }
                    

                // Momento que seta a data de instalacoes futuras como concluido
                if(this.posvenda.dataHoraInstalacaoFutura == null && this.posvenda.finalizarInstalacaoFutura)
                    this.posvenda.dataHoraInstalacaoFutura = new Date();                  
                                
                
                // Momento que seta a data de entrega como concluido
                if(this.posvenda.dataHoraEntrega == null && this.posvenda.finalizarEntrega)
                    this.posvenda.dataHoraEntrega = new Date(); 
                
                // Momento que seta a data de instalacao como concluido
                if(this.posvenda.dataHoraEmInstalacao == null && this.posvenda.finalizarInstalacao)
                    this.posvenda.dataHoraEmInstalacao = new Date();                     


                // Momento que seta a data de instalacao incompleta como concluido
                if(this.posvenda.dataHoraInstalacaoIncompleta == null && this.posvenda.terminarPosvenda)
                    this.posvenda.dataHoraInstalacaoIncompleta = new Date();                                         
                                                                               

                this.$http.post('/api/posvenda/edit', this.posvenda)
                    .then(res => {
                        this.salvandoDados = false;                                        
                        this.$router.push({ name: 'posvenda'});
                        
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        // restrições guia
                        if (err.status == 404) {
    
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: err.body,
                                    type: 'is-danger',
                                    queue: false
                                });                                                            
                        }
                        else {
                            const primeiralinha = err.body.substring(0, err.body.indexOf('\n'))
                            console.log('ERRO:' + err.body)

                            this.$buefy.toast.open({
                                duration: 10000,
                                message: primeiralinha,
                                type: 'is-danger',
                                queue: false
                            })
                        }
                    }).finally(() => {                                
                        this.incluirObservacao();
                        
                    });
           

                return true;
                
            },            
            enviaSalvarVenda() {
                
                this.salvandoDados = true;
                if(this.venda.dataHoraRevisao == null &&  this.venda.revisado)
                    this.venda.dataHoraRevisao = new Date();   

                // Momento que seta a data do projeto como validado
                if(this.venda.dataHoraValidacao == null &&  this.venda.validado)
                    this.venda.dataHoraValidacao = new Date();   

                // Momento que seta a data do financeiro como concluido
                if(this.venda.dataHoraFinanceiro == null &&  this.venda.financeiro)
                    this.venda.dataHoraFinanceiro = new Date();   

                // Momento que seta a data dos eletros como concluido
                if(this.venda.dataHoraEletro == null &&  this.venda.eletro)
                    this.venda.dataHoraEletro = new Date();  

                // Momento que seta a data dos eletros como concluido
                if(this.venda.dataHoraAssinatura == null &&  this.venda.assinatura)
                    this.venda.dataHoraAssinatura = new Date();                  
                
                // Momento que seta a data do financmedidoreiro como concluido
                if(this.venda.dataHoraMedidor == null &&  this.venda.medidor)
                    this.venda.dataHoraMedidor = new Date();        

                // Momento que seta a data do conferenciaAutorizada como concluido
                if(this.venda.dataHoraConferenciaAutorizada == null &&  this.venda.conferenciaAutorizada)
                    this.venda.dataHoraConferenciaAutorizada = new Date();        

                // Momento que seta a data do conferenciaConcluida como concluido
                if(this.venda.dataHoraConferenciaConcluida == null &&  this.venda.conferenciaConcluida)
                    this.venda.dataHoraConferenciaConcluida = new Date();        
                
                // Momento que seta a data do envioProducao como concluido
                if(this.venda.dataHoraEnvioProducao == null &&  this.venda.envioProducao)
                    this.venda.dataHoraEnvioProducao = new Date();        
                
                // Momento que seta a data do finalizarEfinance como concluido
                if(this.venda.dataHoraFinalizarEfinance == null &&  this.venda.finalizarEfinance)
                    this.venda.dataHoraFinalizarEfinance = new Date();        
                
                // Momento que seta a data do plantas como concluido
                if(this.venda.dataHoraPlantas == null &&  this.venda.plantasok)
                    this.venda.dataHoraPlantas = new Date();                        
                  
                                                               
                this.$http.post('/api/venda/edit', this.venda)
                    .then(res => {
                        this.salvandoDados = false;                                        
                        this.$router.push({ name: 'venda'});
                        
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        // restrições guia
                        if (err.status == 404) {
    
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: err.body,
                                    type: 'is-danger',
                                    queue: false
                                });                                
                            
                        }
                        else {

                            const primeiralinha = err.body.substring(0, err.body.indexOf('\n'))
                            console.log('ERRO:' + err.body)

                            this.$buefy.toast.open({
                                duration: 10000,
                                message: primeiralinha,
                                type: 'is-danger',
                                queue: false
                            })
                        }
                    }).finally(() => {                                
                        this.incluirObservacao();
                        
                        if(this.venda.envioProducao)
                            this.notificarConclusaoEnvioProducao();                        
                    });
           
                           

                return true;
                
            },
            enviaSalvarGuia() {
                
                this.salvandoDados = true;
                if (this.guia.unidadeDeCadastroId == null || this.guia.unidadeDeCadastroId==0  ) {                    
                    this.guia.unidadeDeCadastroId = this.unidadeId;
                }

                if(!this.guia.prazoProjetoEmDias)
                    this.guia.prazoProjetoEmDias = 0;
                                    
                                                                              
                this.$http.post('/api/atendimento/edit', this.guia)
                    .then(res => {
                        this.salvandoDados = false;                                        
                        this.$router.push({ name: 'atendimento'});
                        
                    })
                    .catch(err => {
                        this.salvandoDados = false;
                        // restrições guia
                        if (err.status == 404) {
    
                                this.$buefy.toast.open({
                                    duration: 10000,
                                    message: err.body,
                                    type: 'is-danger',
                                    queue: false
                                });                                
                            
                        }
                        else {

                            const primeiralinha = err.body.substring(0, err.body.indexOf('\n'))
                            console.log('ERRO:' + err.body)

                            this.$buefy.toast.open({
                                duration: 10000,
                                message: err.body,
                                type: 'is-danger',
                                queue: false
                            })
                        }
                    }).finally(() => {                                
                        this.incluirObservacao();
                    });
           
           
           
           },
           loadInformacoesGuia() {
                if (this.guia.clienteId == null) {
                    this.$buefy.toast.open({
                        message: this.$t('GUIAATENDIMENTO.CLIENTEDESATIVADO'),
                        type: 'is-danger',
                        duration: 10000
                    })
                    this.$router.push('/atendimento')
                }

                if (this.guia.dataHoraCadastro != null) {
                    this.guia.dataHoraCadastro = new Date(this.guia.dataHoraCadastro);
                    this.guia.dataHoraCadastroStr = this.$moment( this.guia.dataHoraCadastro).format("DD/MM/YYYY HH:mm");
                }
                else {
                    this.guia.dataHoraCadastro = new Date();
                    this.guia.dataHoraCadastroStr = this.$moment( this.guia.dataHoraCadastro).format("DD/MM/YYYY HH:mm");
                }

            },           
            loadGuia() {
                this.activeItemTab = 0;

                if(this.$route.params.atualizarUnidadeId){
                    this.$store.commit('SET_UNIDADE', this.$route.params.atualizarUnidadeId);
                }

                if (this.$route.params.clienteId) {
                    this.$http.get('/api/atendimento/create?clienteId=' + this.$route.params.clienteId + '&unidadeId=' + this.unidadeId)
                    .then(res => {
                        this.guia = res.data;
                        this.loadInformacoesGuia();                        
                    })
                    
                }
              
                if (this.$route.params.id) {

                    this.isLoading = true;    
                    this.$http.get('/api/atendimento/edit?id=' + this.$route.params.id)
                        .then(res => {                            
                            this.guia = res.data[0]
                            
                            if (this.guia.dataHoraCadastro != null) 
                                this.guia.dataHoraCadastroStr = this.$moment( this.guia.dataHoraCadastro).format("DD/MM/YYYY HH:mm");

                            if (this.guia.dataHoraAutorizacao !=null){
                                this.guia.autorizacao = true;                                
                            }

                            if (this.guia.dataHoraProjeto !=null)
                                this.guia.projeto = true;                            

                            /*
                            if(this.guia.status ==2){
                                this.negociacao = "desistencia";
                            }else if(this.guia.status ==1){
                                this.negociacao = "venda";
                            }else if(this.guia.status ==3){
                                this.negociacao = "emespera";
                            }
                            */

                        }).finally(() => {
                            this.loadAnexos();
                            this.loadAmbientes();
                            this.loadHistoricoAlteracoes();
                            this.carregarAgendaGuia(1);
                            this.verificarCamposDisable();

                            if(this.guia.status == 5 || this.fluxoPosVenda){
                                this.tabsPrincipal = "posvenda";
                                this.mudouTabPrincipal("posvenda");
                            } else if(this.guia.status == 1 || this.fluxoVenda) {
                                this.tabsPrincipal = "venda";
                                this.mudouTabPrincipal("venda");
                            }                           
                            this.isLoading = false;    
                        });

                        
                }
                
            },   
            
            verificarCamposDisable(){

                if( this.guia.dataHoraVenda !=null){
                    this.campoDisableTemperatura = true;
                    this.campoDisabledPrazoVendedor = true;                    
                    return;
                }

                if(this.guia.temperatura==undefined ){
                    this.campoDisableTemperatura = false;
                }else if (!this.isInRole('atendimento-alterar-prioridade')){
                    this.campoDisableTemperatura = true;
                }
                
                if(this.guia.prazoVendedorEmDias==0 ){
                    this.campoDisabledPrazoVendedor = false;
                }else if (!this.isInRole('atendimento-alterar-prioridade')){
                    this.campoDisabledPrazoVendedor = true;
                }     
                                      
                
            }                
        }
    }
</script>